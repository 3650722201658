import React from 'react';
import PropTypes from 'prop-types';

const GeometricImage = ({ colors, no }) => {
    const size = 150; // Size of the SVG canvas
    const rectSize = size / 20; // Size of each rectangle in the 20x20 grid

    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            {/* Grid of Rectangles */}
            {Array.from({ length: 20 }).map((_, rowIndex) => (
                Array.from({ length: 20 }).map((_, colIndex) => {
                    const colorIndex = (rowIndex * 5 + no * colIndex) % colors.length;
                    const color = colors[colorIndex];
                    return (
                        <rect
                            key={`${rowIndex}-${colIndex}`}
                            x={colIndex * rectSize}
                            y={rowIndex * rectSize}
                            width={rectSize}
                            height={rectSize}
                            fill={color}
                        />
                    );
                })
            ))}
        </svg>
    );
};

GeometricImage.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GeometricImage;
