import React from 'react';
import {
  Link,
  Route,
  HashRouter as Router,
  Switch,
} from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import ButtonContainer from './components/buttonContainer/ButtonContainer';
import TrustedCompanies from './components/trustedCompanies/TrustedCompanies';
import Feedback from './components/feedback/Feedback';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';
import ResponsiveCards from './components/responsiveCards/ResponsiveCards';
import LegendsOfFunctions from './components/legendsOfFunctions/LegendsOfFunctions.js';
import Welcome from './components/welcome/Welcome'
import SearchBox from './components/searchBar/SearchBar.js'

import './Home.css'; // Import CSS for styling

const handleButtonClick = (company) => {
  // Handle button click action
  console.log(`Clicked on ${company.name}`);
};

// Side navigation
function w3_open() {
  var x = document.getElementById("mySidebar");
  x.style.width = "100%";
  x.style.fontSize = "40px";
  x.style.paddingTop = "10%";
  x.style.display = "block";
}
function w3_close() {
  document.getElementById("mySidebar").style.display = "none";
}



// Main App component
function Home() {
  return (
    <div>
      <TopMenu w3_open={w3_open}/>
      <SideNavigation w3_close={w3_close}/>
      <Header w3_open={w3_open}/>
      <SearchBox/>
      <ButtonContainer handleButtonClick={handleButtonClick} />
      <Welcome/>
      
      <ResponsiveCards />
      <Feedback/>
      
      <LegendsOfFunctions />
      <TrustedCompanies/>
      
      <Footer />
    </div>
  );
}

export default Home;
