import React, { useState, useEffect } from 'react';
import CategoryList from './components/categoryList/CategoryList';
import FoodCard from './components/foodCard/FoodCard';
import SupplierModal from './components/supplierModal/SupplierModal';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';
import Pagination from './components/pagination/Pagination';

const FoodCatalog = () => {
  const [searchField, setSearchField] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [foods, setFoods] = useState([]);
  const [filteredFoods, setFilteredFoods] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);

  const itemsPerPage = 12; // Number of items to display per page

  const categories = ['All', 'Fast Food', 'Desserts', 'Beverages', 'Vegetable', 'Fruit'];

  useEffect(() => {
    // Fetch food data from an API or define it locally
    const foodData = [
      // Example food data
      {
        id: 1, name: 'Cheeseburger', description: 'Beef patty with cheese', category: 'Fast Food', suppliers: [{ id: 1, name: 'Burger Supplier A', details: 'Details A' }],
        retailSuppliers: [
          { id: 1, name: 'Retail Supplier A', price: '$5', quality: 'High', quantity: '50', address: '123 Main St', telephone: '123-456-7890', orderMechanism: 'Online' },
          { id: 2, name: 'Retail Supplier B', price: '$5.5', quality: 'Medium', quantity: '40', address: '456 Main St', telephone: '123-456-7891', orderMechanism: 'Phone' }
        ],
        wholesaleSuppliers: [
          { id: 1, name: 'Wholesale Supplier A', price: '$4', quality: 'High', quantity: '500', address: '456 Market St', telephone: '123-456-7890', orderMechanism: 'Phone' },
          { id: 2, name: 'Wholesale Supplier B', price: '$3.8', quality: 'Medium', quantity: '600', address: '789 Market St', telephone: '123-456-7892', orderMechanism: 'Online' }
        ]
      },
      {
        id: 2, name: 'Pizza', description: 'Cheesy goodness', category: 'Fast Food', suppliers: [{ id: 2, name: 'Pizza Supplier B', details: 'Details B' }],
        retailSuppliers: [
          { id: 100, name: 'Retail Supplier VVVV', price: '$1', quality: 'High', quantity: '100', address: '789 Orchard Rd', telephone: '123-456-7890', orderMechanism: 'In-Store' },
          { id: 101, name: 'Retail Supplier W', price: '$1.2', quality: 'Medium', quantity: '80', address: '123 Orchard Rd', telephone: '123-456-7893', orderMechanism: 'Phone' }
        ],
        wholesaleSuppliers: [
          { id: 100, name: 'Wholesale Supplier VVVV', price: '$0.8', quality: 'High', quantity: '1000', address: '101 Farm Rd', telephone: '123-456-7890', orderMechanism: 'Email' },
          { id: 101, name: 'Wholesale Supplier W', price: '$0.75', quality: 'Medium', quantity: '1200', address: '202 Farm Rd', telephone: '123-456-7894', orderMechanism: 'Online' }
        ]
      },
      { id: 3, name: 'French Fries', description: 'Crispy golden fries', category: 'Fast Food', suppliers: [{ id: 3, name: 'Fries Supplier C', details: 'Details C' }] },
      { id: 4, name: 'Ice Cream', description: 'Vanilla ice cream', category: 'Desserts', suppliers: [{ id: 4, name: 'Ice Cream Supplier D', details: 'Details D' }] },
      { id: 5, name: 'Chocolate Cake', description: 'Rich chocolate flavor', category: 'Desserts', suppliers: [{ id: 5, name: 'Cake Supplier E', details: 'Details E' }] },
      { id: 6, name: 'Coca Cola', description: 'Classic soda drink', category: 'Beverages', suppliers: [{ id: 6, name: 'Coca Cola Supplier F', details: 'Details F' }] },
      { id: 7, name: 'Apple Juice', description: 'Fresh apple juice', category: 'Beverages', suppliers: [{ id: 7, name: 'Juice Supplier G', details: 'Details G' }] },
      { id: 8, name: 'Carrot', description: 'Fresh organic carrot', category: 'Vegetable', suppliers: [{ id: 8, name: 'Vegetable Supplier H', details: 'Details H' }] },
      { id: 9, name: 'Spinach', description: 'Leafy green spinach', category: 'Vegetable', suppliers: [{ id: 9, name: 'Vegetable Supplier I', details: 'Details I' }] },
      { id: 10, name: 'Apple', description: 'Crisp red apple', category: 'Fruit', suppliers: [{ id: 10, name: 'Fruit Supplier J', details: 'Details J' }] },
      { id: 11, name: 'Banana', description: 'Ripe yellow banana', category: 'Fruit', suppliers: [{ id: 11, name: 'Fruit Supplier K', details: 'Details K' }] },
      { id: 12, name: 'Hot Dog', description: 'Classic American hot dog', category: 'Fast Food', suppliers: [{ id: 12, name: 'Hot Dog Supplier L', details: 'Details L' }] },
      { id: 13, name: 'Tacos', description: 'Mexican tacos with beef', category: 'Fast Food', suppliers: [{ id: 13, name: 'Taco Supplier M', details: 'Details M' }] },
      { id: 14, name: 'Donut', description: 'Sweet glazed donut', category: 'Desserts', suppliers: [{ id: 14, name: 'Donut Supplier N', details: 'Details N' }] },
      { id: 15, name: 'Brownie', description: 'Chocolate fudge brownie', category: 'Desserts', suppliers: [{ id: 15, name: 'Brownie Supplier O', details: 'Details O' }] },
      { id: 16, name: 'Lemonade', description: 'Refreshing lemon drink', category: 'Beverages', suppliers: [{ id: 16, name: 'Lemonade Supplier P', details: 'Details P' }] },
      { id: 17, name: 'Orange Juice', description: 'Fresh orange juice', category: 'Beverages', suppliers: [{ id: 17, name: 'Juice Supplier Q', details: 'Details Q' }] },
      { id: 18, name: 'Broccoli', description: 'Fresh green broccoli', category: 'Vegetable', suppliers: [{ id: 18, name: 'Vegetable Supplier R', details: 'Details R' }] },
      { id: 19, name: 'Cucumber', description: 'Crunchy cucumber', category: 'Vegetable', suppliers: [{ id: 19, name: 'Vegetable Supplier S', details: 'Details S' }] },
      { id: 20, name: 'Strawberry', description: 'Fresh sweet strawberry', category: 'Fruit', suppliers: [{ id: 20, name: 'Fruit Supplier T', details: 'Details T' }] },
      { id: 21, name: 'Pineapple', description: 'Tropical pineapple', category: 'Fruit', suppliers: [{ id: 21, name: 'Fruit Supplier U', details: 'Details U' }] },
      { id: 22, name: 'Burger', description: 'Juicy beef burger', category: 'Fast Food', suppliers: [{ id: 22, name: 'Burger Supplier V', details: 'Details V' }] },
      { id: 23, name: 'Nachos', description: 'Crispy nachos with cheese', category: 'Fast Food', suppliers: [{ id: 23, name: 'Nachos Supplier W', details: 'Details W' }] },
      { id: 24, name: 'Cupcake', description: 'Vanilla cupcake with frosting', category: 'Desserts', suppliers: [{ id: 24, name: 'Cupcake Supplier X', details: 'Details X' }] },
      { id: 25, name: 'Cheesecake', description: 'Creamy cheesecake', category: 'Desserts', suppliers: [{ id: 25, name: 'Cheesecake Supplier Y', details: 'Details Y' }] },
      { id: 26, name: 'Iced Tea', description: 'Chilled iced tea', category: 'Beverages', suppliers: [{ id: 26, name: 'Iced Tea Supplier Z', details: 'Details Z' }] },
      { id: 27, name: 'Milkshake', description: 'Chocolate milkshake', category: 'Beverages', suppliers: [{ id: 27, name: 'Milkshake Supplier AA', details: 'Details AA' }] },
      { id: 28, name: 'Tomato', description: 'Fresh red tomato', category: 'Vegetable', suppliers: [{ id: 28, name: 'Vegetable Supplier BB', details: 'Details BB' }] },
      { id: 29, name: 'Pepper', description: 'Sweet bell pepper', category: 'Vegetable', suppliers: [{ id: 29, name: 'Vegetable Supplier CC', details: 'Details CC' }] },
      { id: 30, name: 'Grapes', description: 'Juicy green grapes', category: 'Fruit', suppliers: [{ id: 30, name: 'Fruit Supplier DD', details: 'Details DD' }] },
      { id: 31, name: 'Watermelon', description: 'Refreshing watermelon', category: 'Fruit', suppliers: [{ id: 31, name: 'Fruit Supplier EE', details: 'Details EE' }] },
      { id: 32, name: 'Fried Chicken', description: 'Crispy fried chicken', category: 'Fast Food', suppliers: [{ id: 32, name: 'Fried Chicken Supplier FF', details: 'Details FF' }] },
      { id: 33, name: 'Pasta', description: 'Italian pasta with sauce', category: 'Fast Food', suppliers: [{ id: 33, name: 'Pasta Supplier GG', details: 'Details GG' }] },
      { id: 34, name: 'Pudding', description: 'Creamy vanilla pudding', category: 'Desserts', suppliers: [{ id: 34, name: 'Pudding Supplier HH', details: 'Details HH' }] },
      { id: 35, name: 'Macaron', description: 'French macaron', category: 'Desserts', suppliers: [{ id: 35, name: 'Macaron Supplier II', details: 'Details II' }] },
      { id: 36, name: 'Soda', description: 'Sparkling soda drink', category: 'Beverages', suppliers: [{ id: 36, name: 'Soda Supplier JJ', details: 'Details JJ' }] },
      { id: 37, name: 'Coffee', description: 'Hot brewed coffee', category: 'Beverages', suppliers: [{ id: 37, name: 'Coffee Supplier KK', details: 'Details KK' }] },
      { id: 38, name: 'Lettuce', description: 'Fresh green lettuce', category: 'Vegetable', suppliers: [{ id: 38, name: 'Vegetable Supplier LL', details: 'Details LL' }] },
      { id: 39, name: 'Eggplant', description: 'Fresh eggplant', category: 'Vegetable', suppliers: [{ id: 39, name: 'Vegetable Supplier MM', details: 'Details MM' }] },
      { id: 40, name: 'Blueberries', description: 'Sweet blueberries', category: 'Fruit', suppliers: [{ id: 40, name: 'Fruit Supplier NN', details: 'Details NN' }] },
      { id: 41, name: 'Mango', description: 'Tropical mango', category: 'Fruit', suppliers: [{ id: 41, name: 'Fruit Supplier OO', details: 'Details OO' }] },
      { id: 42, name: 'Shawarma', description: 'Middle Eastern shawarma', category: 'Fast Food', suppliers: [{ id: 42, name: 'Shawarma Supplier PP', details: 'Details PP' }] },
      { id: 43, name: 'Sub Sandwich', description: 'Submarine sandwich', category: 'Fast Food', suppliers: [{ id: 43, name: 'Sandwich Supplier QQ', details: 'Details QQ' }] },
      { id: 44, name: 'Apple Pie', description: 'Classic apple pie', category: 'Desserts', suppliers: [{ id: 44, name: 'Pie Supplier RR', details: 'Details RR' }] },
      { id: 45, name: 'Mousse', description: 'Chocolate mousse', category: 'Desserts', suppliers: [{ id: 45, name: 'Mousse Supplier SS', details: 'Details SS' }] },
      { id: 46, name: 'Smoothie', description: 'Fruit smoothie', category: 'Beverages', suppliers: [{ id: 46, name: 'Smoothie Supplier TT', details: 'Details TT' }] },
      { id: 47, name: 'Green Tea', description: 'Hot green tea', category: 'Beverages', suppliers: [{ id: 47, name: 'Tea Supplier UU', details: 'Details UU' }] },
      { id: 48, name: 'Potato', description: 'Fresh potatoes', category: 'Vegetable', suppliers: [{ id: 48, name: 'Vegetable Supplier VV', details: 'Details VV' }] },
      { id: 49, name: 'Cauliflower', description: 'Fresh cauliflower', category: 'Vegetable', suppliers: [{ id: 49, name: 'Vegetable Supplier WW', details: 'Details WW' }] },
      { id: 50, name: 'Cherry', description: 'Sweet cherries', category: 'Fruit', suppliers: [{ id: 50, name: 'Fruit Supplier XX', details: 'Details XX' }] },
      { id: 51, name: 'Peach', description: 'Juicy peach', category: 'Fruit', suppliers: [{ id: 51, name: 'Fruit Supplier YY', details: 'Details YY' }] },
      { id: 52, name: 'Falafel', description: 'Middle Eastern falafel', category: 'Fast Food', suppliers: [{ id: 52, name: 'Falafel Supplier ZZ', details: 'Details ZZ' }] },
      { id: 53, name: 'Quesadilla', description: 'Cheese quesadilla', category: 'Fast Food', suppliers: [{ id: 53, name: 'Quesadilla Supplier AAA', details: 'Details AAA' }] },
      { id: 54, name: 'Baklava', description: 'Sweet baklava', category: 'Desserts', suppliers: [{ id: 54, name: 'Baklava Supplier BBB', details: 'Details BBB' }] },
      { id: 55, name: 'Tiramisu', description: 'Italian tiramisu', category: 'Desserts', suppliers: [{ id: 55, name: 'Tiramisu Supplier CCC', details: 'Details CCC' }] },
      { id: 56, name: 'Frappe', description: 'Chilled coffee frappe', category: 'Beverages', suppliers: [{ id: 56, name: 'Frappe Supplier DDD', details: 'Details DDD' }] },
      { id: 57, name: 'Hot Chocolate', description: 'Hot cocoa drink', category: 'Beverages', suppliers: [{ id: 57, name: 'Hot Chocolate Supplier EEE', details: 'Details EEE' }] },
      { id: 58, name: 'Onion', description: 'Fresh onions', category: 'Vegetable', suppliers: [{ id: 58, name: 'Vegetable Supplier FFF', details: 'Details FFF' }] },
      { id: 59, name: 'Garlic', description: 'Fresh garlic', category: 'Vegetable', suppliers: [{ id: 59, name: 'Vegetable Supplier GGG', details: 'Details GGG' }] },
      { id: 60, name: 'Kiwi', description: 'Fresh kiwi', category: 'Fruit', suppliers: [{ id: 60, name: 'Fruit Supplier HHH', details: 'Details HHH' }] },
      { id: 61, name: 'Blackberry', description: 'Sweet blackberries', category: 'Fruit', suppliers: [{ id: 61, name: 'Fruit Supplier III', details: 'Details III' }] },
      { id: 62, name: 'Chicken Nuggets', description: 'Crispy chicken nuggets', category: 'Fast Food', suppliers: [{ id: 62, name: 'Nuggets Supplier JJJ', details: 'Details JJJ' }] },
      { id: 63, name: 'Gyro', description: 'Greek gyro', category: 'Fast Food', suppliers: [{ id: 63, name: 'Gyro Supplier KKK', details: 'Details KKK' }] },
      { id: 64, name: 'Eclair', description: 'Chocolate eclair', category: 'Desserts', suppliers: [{ id: 64, name: 'Eclair Supplier LLL', details: 'Details LLL' }] },
      { id: 65, name: 'Crepe', description: 'French crepe', category: 'Desserts', suppliers: [{ id: 65, name: 'Crepe Supplier MMM', details: 'Details MMM' }] },
      { id: 66, name: 'Mint Tea', description: 'Refreshing mint tea', category: 'Beverages', suppliers: [{ id: 66, name: 'Mint Tea Supplier NNN', details: 'Details NNN' }] },
      { id: 67, name: 'Energy Drink', description: 'High-energy drink', category: 'Beverages', suppliers: [{ id: 67, name: 'Energy Drink Supplier OOO', details: 'Details OOO' }] },
      { id: 68, name: 'Zucchini', description: 'Fresh zucchini', category: 'Vegetable', suppliers: [{ id: 68, name: 'Vegetable Supplier PPP', details: 'Details PPP' }] },
      { id: 69, name: 'Radish', description: 'Fresh radish', category: 'Vegetable', suppliers: [{ id: 69, name: 'Vegetable Supplier QQQ', details: 'Details QQQ' }] },
      { id: 70, name: 'Pomegranate', description: 'Fresh pomegranate', category: 'Fruit', suppliers: [{ id: 70, name: 'Fruit Supplier RRR', details: 'Details RRR' }] },
      { id: 71, name: 'Papaya', description: 'Tropical papaya', category: 'Fruit', suppliers: [{ id: 71, name: 'Fruit Supplier SSS', details: 'Details SSS' }] },
      { id: 72, name: 'Sloppy Joe', description: 'Messy sloppy joe', category: 'Fast Food', suppliers: [{ id: 72, name: 'Sloppy Joe Supplier TTT', details: 'Details TTT' }] },
      { id: 73, name: 'Burrito', description: 'Mexican burrito', category: 'Fast Food', suppliers: [{ id: 73, name: 'Burrito Supplier UUU', details: 'Details UUU' }] },
      { id: 74, name: 'Muffin', description: 'Blueberry muffin', category: 'Desserts', suppliers: [{ id: 74, name: 'Muffin Supplier VVV', details: 'Details VVV' }] },
      { id: 75, name: 'Pavlova', description: 'Meringue-based dessert', category: 'Desserts', suppliers: [{ id: 75, name: 'Pavlova Supplier WWW', details: 'Details WWW' }] },
      { id: 76, name: 'Cider', description: 'Apple cider drink', category: 'Beverages', suppliers: [{ id: 76, name: 'Cider Supplier XXX', details: 'Details XXX' }] },
      { id: 77, name: 'Mojito', description: 'Refreshing mojito', category: 'Beverages', suppliers: [{ id: 77, name: 'Mojito Supplier YYY', details: 'Details YYY' }] },
      { id: 78, name: 'Beetroot', description: 'Fresh beetroot', category: 'Vegetable', suppliers: [{ id: 78, name: 'Vegetable Supplier ZZZ', details: 'Details ZZZ' }] },
      { id: 79, name: 'Asparagus', description: 'Fresh asparagus', category: 'Vegetable', suppliers: [{ id: 79, name: 'Vegetable Supplier AAAA', details: 'Details AAAA' }] },
      { id: 80, name: 'Plum', description: 'Sweet plum', category: 'Fruit', suppliers: [{ id: 80, name: 'Fruit Supplier BBBB', details: 'Details BBBB' }] },
      { id: 81, name: 'Lime', description: 'Sour lime', category: 'Fruit', suppliers: [{ id: 81, name: 'Fruit Supplier CCCC', details: 'Details CCCC' }] },
      { id: 82, name: 'Corn Dog', description: 'Classic corn dog', category: 'Fast Food', suppliers: [{ id: 82, name: 'Corn Dog Supplier DDDD', details: 'Details DDDD' }] },
      { id: 83, name: 'Chili Dog', description: 'Chili-topped hot dog', category: 'Fast Food', suppliers: [{ id: 83, name: 'Chili Dog Supplier EEEE', details: 'Details EEEE' }] },
      { id: 84, name: 'Gelato', description: 'Italian gelato', category: 'Desserts', suppliers: [{ id: 84, name: 'Gelato Supplier FFFF', details: 'Details FFFF' }] },
      { id: 85, name: 'Sorbet', description: 'Fruit-flavored sorbet', category: 'Desserts', suppliers: [{ id: 85, name: 'Sorbet Supplier GGGG', details: 'Details GGGG' }] },
      { id: 86, name: 'Sparkling Water', description: 'Carbonated water', category: 'Beverages', suppliers: [{ id: 86, name: 'Sparkling Water Supplier HHHH', details: 'Details HHHH' }] },
      { id: 87, name: 'Milk', description: 'Fresh milk', category: 'Beverages', suppliers: [{ id: 87, name: 'Milk Supplier IIII', details: 'Details IIII' }] },
      { id: 88, name: 'Cabbage', description: 'Fresh cabbage', category: 'Vegetable', suppliers: [{ id: 88, name: 'Vegetable Supplier JJJJ', details: 'Details JJJJ' }] },
      { id: 89, name: 'Mushroom', description: 'Fresh mushrooms', category: 'Vegetable', suppliers: [{ id: 89, name: 'Vegetable Supplier KKKK', details: 'Details KKKK' }] },
      { id: 90, name: 'Pear', description: 'Juicy pear', category: 'Fruit', suppliers: [{ id: 90, name: 'Fruit Supplier LLLL', details: 'Details LLLL' }] },
      { id: 91, name: 'Coconut', description: 'Tropical coconut', category: 'Fruit', suppliers: [{ id: 91, name: 'Fruit Supplier MMMM', details: 'Details MMMM' }] },
      { id: 92, name: 'Chicken Sandwich', description: 'Grilled chicken sandwich', category: 'Fast Food', suppliers: [{ id: 92, name: 'Chicken Sandwich Supplier NNNN', details: 'Details NNNN' }] },
      { id: 93, name: 'Calzone', description: 'Stuffed calzone', category: 'Fast Food', suppliers: [{ id: 93, name: 'Calzone Supplier OOOO', details: 'Details OOOO' }] },
      { id: 94, name: 'Bread Pudding', description: 'Classic bread pudding', category: 'Desserts', suppliers: [{ id: 94, name: 'Bread Pudding Supplier PPPP', details: 'Details PPPP' }] },
      { id: 95, name: 'Banoffee Pie', description: 'Banana and toffee pie', category: 'Desserts', suppliers: [{ id: 95, name: 'Banoffee Pie Supplier QQQQ', details: 'Details QQQQ' }] },
      { id: 96, name: 'Herbal Tea', description: 'Healthy herbal tea', category: 'Beverages', suppliers: [{ id: 96, name: 'Herbal Tea Supplier RRRR', details: 'Details RRRR' }] },
      { id: 97, name: 'Root Beer', description: 'Classic root beer', category: 'Beverages', suppliers: [{ id: 97, name: 'Root Beer Supplier SSSS', details: 'Details SSSS' }] },
      { id: 98, name: 'Kale', description: 'Fresh kale', category: 'Vegetable', suppliers: [{ id: 98, name: 'Vegetable Supplier TTTT', details: 'Details TTTT' }] },
      { id: 99, name: 'Brussels Sprouts', description: 'Fresh Brussels sprouts', category: 'Vegetable', suppliers: [{ id: 99, name: 'Vegetable Supplier UUUU', details: 'Details UUUU' }] },
      { id: 100, name: 'Apricot', description: 'Sweet apricot', category: 'Fruit', suppliers: [{ id: 100, name: 'Fruit Supplier VVVV', details: 'Details VVVV' }] }


      // Add more food items
    ];
    setFoods(foodData);
  }, []);

  useEffect(() => {

    const filteredFoodData = foods.filter((food) => {
      return (selectedCategory === 'All' || food.category === selectedCategory) &&
        food.name.toLowerCase().includes(searchField.toLowerCase());
    });
    setFilteredFoods(filteredFoodData);
    const totalPages = Math.ceil(filteredFoodData.length / itemsPerPage);
    setTotalPages(totalPages);

    const currentPageData = filteredFoodData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
    setCurrentPageData(currentPageData);

    setFilteredFoods(
      foods.filter(food =>
        food.name.toLowerCase().includes(searchField.toLowerCase()) &&
        (selectedCategory === '' || food.category === selectedCategory)
      )
    );
  }, [searchField, selectedCategory, foods, currentPage]);

  const handleSearchChange = event => setSearchField(event.target.value);
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };
  const handleOpenSupplierModal = food => {
    setSelectedFood(food);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Side navigation
  function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }

  return (
    <div>
      <TopMenu w3_open={w3_open} handleSearchChange={handleSearchChange} />
      <SideNavigation w3_close={w3_close} />
      <Header w3_open={w3_open} handleSearchChange={handleSearchChange} />


      <div className="w3-row">
        <div className="w3-col m3">
          <CategoryList categories={categories} selectCategory={handleCategorySelect} selectedCategory={selectedCategory} />
        </div>
        <div className="w3-col m9">
          <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
          <div className="w3-row-padding">
            {currentPageData && currentPageData.map(food => (
              <div className="w3-col m4" key={food.id}>
                <FoodCard food={food} openSupplierModal={handleOpenSupplierModal} />
              </div>
            ))}
            {currentPageData && currentPageData.length === 0 && (
              <div className="w3-col w3-center w3-padding-24">
                <p>No food items found.</p>
              </div>
            )}
          </div>
          <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        </div>
      </div>
      {isModalOpen && <SupplierModal selectedFood={selectedFood} closeModal={handleCloseModal} />}

      <Footer />
    </div>
  );
};

export default FoodCatalog;
