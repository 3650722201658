import React, { useEffect, useState } from 'react';
import './SideNavigation.css'; // Import the custom CSS file

const SideNavigation = ({w3_close}) => {
    
    return (
        <nav className="w3-sidebar w3-bar-block w3-card w3-animate-left w3-center" style={{ display: 'none' }} id="mySidebar">
          <h1 className="w3-xxxlarge">RISARA GROUP</h1>
          <button className="w3-bar-item w3-button " onClick={w3_close}>Close <i className="fa fa-remove"></i></button>
          <a href="#" className="w3-bar-item w3-button w3-text-orange">Risara Education</a>
          <a href="#" className="w3-bar-item w3-button w3-text-green">Risara Nature Care</a>
          <a href="#" className="w3-bar-item w3-button w3-text-blue">Risara Elders Care</a>
          <a href="#" className="w3-bar-item w3-button w3-text-red">Risara Food Care</a>
        </nav>
      );
};

export default SideNavigation;