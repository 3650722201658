import React, { useState } from 'react';
import './Welcome.css'; // Import CSS for styling

const Welcome = () => {
  return (
    <div >



        <div className="w3-center" >
          <h1>Welcome to Risara Group</h1>
          <p>Your trusted partner in fostering quality education, environmental sustainability, elder care, and healthy living.</p>
        </div>

        <br />

       
    </div>
  );
};

export default Welcome;