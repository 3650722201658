// ButtonContainer.js
import React from 'react';
import './ButtonContainer.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faBlog, faTree, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ButtonContainer = ({ handleButtonClick, local }) => {
  const companies = [
    { name: 'Food Catalog', icon: faBookOpen, color: 'w3-green', description: 'Explore a wide range of courses designed to empower your future.', href: 'section-a1' },
    { name: 'Suplier Catalog', icon: faBlog, color: 'w3-blue', description: 'Stay updated with our latest articles and insights on education.', href: 'section-a2' },
    { name: 'Food Programs', icon: faTree, color: 'w3-orange', description: 'Join our community and share your knowledge with others.', href: 'section-a3' },
  ];

  const { t } = useTranslation(local);

  const handleButtonClick1 = (company) => {

    const element = document.getElementById(company.href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    handleButtonClick(company);
  };

  return (
    <div className="size_limit w3-container w3-center  w3-margin-top">
      <div className="button-container">
        {companies.map((company, index) => (
          <button key={index} className={`company-button ${company.color}`} onClick={() => handleButtonClick1(company)}>
            <FontAwesomeIcon icon={company.icon} size="3x" />
            <div className="company-info">
              <h1>{t(company.name)}</h1>
              <p>{t(company.description)}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default ButtonContainer;
