// Header.js
import React from 'react';
import './Header.css'; // Import CSS for styling
import Language from '../language/Language'
import { useTranslation } from 'react-i18next';
import ButtonSetTop from '../buttonSetTop/ButtonSet'

const Header = ({ w3_open, buttons, local }) => {
  const { t } = useTranslation(local);

  return (
    <header className="w3-container w3-theme w3-padding myHeader" id="myHeader">
      <i className="fa fa-bars w3-xlarge w3-button w3-theme" onClick={w3_open}></i>
      <ButtonSetTop buttons={buttons} />
      <Language></Language>
      <br /><br />
      <div className="w3-center">
        <h4>{t('header.subtitle')}</h4>
        <h1 className="w3-xxxlarge w3-animate-bottom">{t('header.title')}</h1>
      </div>
    </header>
  );
}

export default Header;
