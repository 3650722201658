// src/components/CourseCard.js
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import GeometricImage from './../geometricImage/GeometricImage';
import './CourseCard.css';

const CourseCard = ({ index, course, handleLinkClick }) => {

  const colors = [
    '#ff9933' ,  '#66cc66'  ,  '#3399ff' ,  '#ff6666', '#11ffff', '#ffff00', '#ff00ff', '#00ffff', '#ffff55','#ff55ff', '#55ffff'
  ];

  return (


    <div className="card" key={index}>
      {
        //<img src={course.image} alt={course.title} />
      }
      <GeometricImage colors={colors} no={course.title.length} />
      <h2>{course.title}</h2>
      <p><strong>Category:</strong> {course.category}</p>
      <p>{course.description}</p>
      <p className="price">{course.price}</p>
      {course.launchUrl.startsWith('http') ? (
        <a href={course.launchUrl} className="btn">
          Launch
        </a>
      ) : (
        <Link to="#" onClick={(event) => handleLinkClick(event, course)} className="btn">
          Launch
        </Link>
      )}
    </div>

  );
};

export default CourseCard;
