


import React from 'react';
import {
  Link,
  Route,
  HashRouter as Router,
  Switch,
} from 'react-router-dom';
//import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import HubHome from './components/hub/home/Home';
import HubGallery from './components/hub/gallery/Gallery';
import HubBlog from './components/hub/blog/Blog';
import HubAbout from './components/hub/about/About';
import HubContact from './components/hub/contact/Contact';

import AiandroboticsHome from './components/aiandrobotics/home/Home';
import AiandroboticsGallery from './components/aiandrobotics/gallery/Gallery';
import AiandroboticsBlog from './components/aiandrobotics/blog/Blog';
import AiandroboticsAbout from './components/aiandrobotics/about/About';
import AiandroboticsContact from './components/aiandrobotics/contact/Contact';

import StudioHome from './components/studio/home/Home';
import StudioGallery from './components/studio/gallery/Gallery';
import StudioBlog from './components/studio/blog/Blog';
import StudioAbout from './components/studio/about/About';
import StudioContact from './components/studio/contact/Contact';

import MarketplaceHome from './components/marketplace/home/Home';
import MarketplaceGallery from './components/marketplace/gallery/Gallery';
import MarketplaceBlog from './components/marketplace/blog/Blog';
import MarketplaceAbout from './components/marketplace/about/About';
import MarketplaceContact from './components/marketplace/contact/Contact';
import FoodCatalog from './components/marketplace/foodCatalog/FoodCatalog';

import EducationHome from './components/education/home/Home';
import EducationGallery from './components/education/gallery/Gallery';
import EducationBlog from './components/education/blog/Blog';
import EducationAbout from './components/education/about/About';
import EducationContact from './components/education/contact/Contact';
import SearchPage from './components/education/searchPage/SearchPage'
import MenueIndex from './components/education/menueIndex/MenueIndex';
import Lesson from './components/education/lesson/Lesson';
import Quiz from './components/education/quiz/Quiz';
import BlogPage from './components/education/blogPage/BlogPage'
import LearningProgress from './components/education/learningProgress/LearningProgress'
import KnowledgeTree from './components/education/catalog/components/knowledgeTree/knowledgeTreeTable/KnowledgeTree';

import NotFound from './components/notFound/NotFound';

import './App.css';

function App() {

  return (
    <Router>
      <Router>
        <Route exact path="/" component={HubHome} />
        <Route exact path="/hub/gallery" component={HubGallery} />
        <Route exact path="/hub/blog" component={HubBlog} />
        <Route exact path="/hub/about" component={HubAbout} />
        <Route exact path="/hub/contact" component={HubContact} />

        <Route exact path="/aiandrobotics" component={AiandroboticsHome} />
        <Route exact path="/aiandrobotics/gallery" component={AiandroboticsGallery} />
        <Route exact path="/aiandrobotics/blog" component={AiandroboticsBlog} />
        <Route exact path="/aiandrobotics/about" component={AiandroboticsAbout} />
        <Route exact path="/aiandrobotics/contact" component={AiandroboticsContact} />

        <Route exact path="/studio" component={StudioHome} />
        <Route exact path="/studio/gallery" component={StudioGallery} />
        <Route exact path="/studio/blog" component={StudioBlog} />
        <Route exact path="/studio/about" component={StudioAbout} />
        <Route exact path="/studio/contact" component={StudioContact} />

        <Route exact path="/marketplace" component={MarketplaceHome} />
        <Route exact path="/marketplace/gallery" component={MarketplaceGallery} />
        <Route exact path="/marketplace/blog" component={MarketplaceBlog} />
        <Route exact path="/marketplace/about" component={MarketplaceAbout} />
        <Route exact path="/marketplace/contact" component={MarketplaceContact} />
        <Route exact path="/marketplace/foodCatalog" component={FoodCatalog} />

        <Route exact path="/education" component={EducationHome} />
        <Route exact path="/education/gallery" component={EducationGallery} />
        <Route exact path="/education/blog" component={EducationBlog} />
        <Route exact path="/education/about" component={EducationAbout} />
        <Route exact path="/education/contact" component={EducationContact} />
        <Route path="/search" component={SearchPage} />
        <Route path="/menue_index" component={MenueIndex} />
        <Route path="/quiz" component={Quiz} />
        <Route path="/lesson" component={Lesson} />
        <Route path="/KnowledgeTree" component={KnowledgeTree} />
        <Route path="/blog" component={BlogPage} />
        <Route path="/learningProgress" component={LearningProgress} />

        <Route path="/notfound" component={NotFound} />
      </Router>
    </Router>
  );
}

export default App;