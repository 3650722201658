import React, { useState } from 'react';

const ImageSlider = ({ items,id }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    const goToIndex = (index) => {
        setCurrentIndex(index);
    };

    return (
        <div className="w3-third w3-container w3-padding-16">
            <div className="w3-card">
                {items[currentIndex].type === 'image' ? (
                    <img src={items[currentIndex].src} alt="Healthy Food" style={{ width: '100%' }} />
                ) : (
                    <iframe
                        width="100%"
                        height="315"
                        src={`${items[currentIndex].src}?autoplay=1`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
            <div className="w3-center w3-padding-32">
                <div className="w3-bar" id={id}>
                    <div href="#" onClick={goToPrevious} className="w3-bar-item w3-button w3-hover-blue">«</div>
                    {items.map((_, index) => (
                        <div
                            href="#"
                            key={index}
                            onClick={() => goToIndex(index)}
                            className={`w3-bar-item w3-button ${currentIndex === index ? 'w3-blue' : ''} w3-hover-blue`}
                        >
                            {index + 1}
                        </div>
                    ))}
                    <div href="#" onClick={goToNext} className="w3-bar-item w3-button w3-hover-blue">»</div>
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
