import React, { useEffect, useState } from 'react';
import './SideNavigation.css'; // Import the custom CSS file
import { useTranslation } from 'react-i18next';
import ButtonSetLeft from '../buttonSetLeft/ButtonSet'
import { FaBook, FaShoppingCart, FaRobot, FaCamera } from 'react-icons/fa'; // Import icons

const SideNavigation = ({ w3_close, buttons, local }) => {
  const { t, i18n } = useTranslation(local);

  const handleButtonClick = (id) => {

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="w3-sidebar w3-bar-block w3-card w3-animate-left w3-center" style={{ display: 'none' }} id="mySidebar">
      <button className=" w3-border  w3-button w3-circle w3-image " onClick={w3_close}><i className="fa fa-remove" style={{
        width: '100px',
        height: '100px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '48px',
        margin: '0 auto',
        color: 'red', borderRadius: '50%'
      }}></i></button>
      <h1 className="w3-xxxlarge"><b>{t("topMenu.risaraGroup")}</b></h1>
      <a onClick={() => { handleButtonClick('section-a1'), w3_close() }} className="w3-bar-item w3-button w3-text-blue"><FaBook size='30' />{t("sideNavigation.education")}</a>
      <a onClick={() => { handleButtonClick('section-a2'), w3_close() }} className="w3-bar-item w3-button w3-text-green"><FaShoppingCart size='30' />{t("sideNavigation.marketplace")}</a>
      <a onClick={() => { handleButtonClick('section-a3'), w3_close() }} className="w3-bar-item w3-button w3-text-orange"><FaRobot size='30' />{t("sideNavigation.AIandRobotics")}</a>
      <a onClick={() => { handleButtonClick('section-a4'), w3_close() }} className="w3-bar-item w3-button w3-text-red"><FaCamera size='30' />{t("sideNavigation.studio")}</a>

      <ButtonSetLeft buttons={buttons} />
    </nav>
  );
};

export default SideNavigation;