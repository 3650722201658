import React, { useEffect, useState } from 'react';
import './SideNavigation.css'; // Import the custom CSS file

const SideNavigation = ({w3_close}) => {
    
    return (
        <nav className="w3-sidebar w3-bar-block w3-card w3-animate-left w3-center" style={{ display: 'none' }} id="mySidebar">
          <button className=" w3-border  w3-button w3-circle w3-image " onClick={w3_close}><i className="fa fa-remove" style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize:'48px',
                  margin: '0 auto',
                  color:'red', borderRadius: '50%' 
                }}></i></button>
          <h1 className="w3-xxxlarge"><b>Risara Education</b></h1>
          <a href="#re" className="w3-bar-item w3-button">RISARA GROUP</a>
          <a href="#re" className="w3-bar-item w3-button w3-text-orange">Food Catalog</a>
          <a href="#rnc" className="w3-bar-item w3-button w3-text-green">Suplier Catalog</a>
          <a href="#rec" className="w3-bar-item w3-button w3-text-blue">Food Programs</a>
        </nav>
      );
};

export default SideNavigation;