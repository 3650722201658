// Footer.js
import React from 'react';
import './Footer.css'; // Import CSS for styling
import SocialMediaIcons from './../socialMediaIcons/SocialMediaIcons';
const Footer = () => {
  return (

    <footer className="w3-container w3-theme-dark w3-padding-16">

      <div className="w3-row">
        <div className="w3-third w3-container">
          <h3>About Risara Group</h3>
          <p>Risara Group is committed to empowering lives and enriching communities through our diverse range of services and initiatives.</p>
        </div>
        <div className="w3-third w3-container">
          <h3>Contact Us</h3>
          <p>Email: info@risaragroup.com</p>
          <p>Phone: +1 123-456-7890</p>
        </div>
        <div className="w3-third w3-container">
          <h3>Follow Us</h3>
          <SocialMediaIcons/>
        </div>
      </div>

      <div className="w3-row">
        <p>&copy; 2024 Risara Group</p>
      </div>
    </footer>

  );

}

export default Footer;
