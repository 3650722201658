import React, { useEffect, useState } from 'react';
import SearchBar from '../searchBar/SearchBar'
import './TopMenu.css'; // Import the custom CSS file

const TopMenu = ({w3_open}) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`top-menu ${isVisible ? 'visible' : ''}`}>
            <a className="w3-bar-item w3-button fa fa-bars w3-xlarge  w3-theme" onClick={w3_open}></a>
            <a href="#re" className="w3-bar-item w3-button w3-theme">Education</a>
            <a href="#rnc" className="w3-bar-item w3-button w3-theme">Nature</a>
            <a href="#rec" className="w3-bar-item w3-button w3-theme">Elders</a>
            <a href="#rfc" className="w3-bar-item w3-button w3-theme">Food</a>
            <SearchBar/>
        </div>
    );
};

export default TopMenu;
