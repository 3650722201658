import React from 'react';
import MathJaxComponent from './mathJaxComponent/MathJaxComponent';

const EquationTextWithImages = ({ content }) => {

  //const string = "Evaluate $4e$arcsin(0)$4$.  sdfs  $1e$arcsin(0)$1$. fegf $1u$arcefwe#efter$1$ dfgdf gdfg$1u$arcswer3243$1$ dfgfgg $1b$rdgfdgdf$1$  $1ul$$2li$arcefwe#efter$2$$2li$arcefwe#efter$2$$2li$arcefwe#efter$2$$1$ ";
  const regex = /\$1e\$([^\$]+)\$1\$/g;
  const splitContent = content.split(regex);

  const equationRegex = /(\$[1e]\$.*?\$1\$)/g;
  const imageRegex = /(\$[1i]\$.*?\$1\$)/g;

  //////exicute regex array start
  var regexArr = [];
  regexArr.push(/\$(4e|4i|4b|4u|4I|4ol|4ul|4li)\$([^$]*?)\$4\$/g);
  regexArr.push(/\$(3e|3i|3b|3u|3I|3ol|3ul|3li)\$([^$]*?)\$3\$/g);
  regexArr.push(/\$(2e|2i|2b|2u|2I|2ol|2ul|2li)\$([^$]*?)\$2\$/g);
  regexArr.push(/\$(1e|1i|1b|1u|1I|1ol|1ul|1li)\$([^$]*?)\$1\$/g);

  var position = 0;

  {
    regexArr.forEach(regX => {
      var fillingArray = [];
      position++;
      let match;
      while ((match = regX.exec(content)) !== null) {
        const placeHolder = match[0];
        const key = match[1];
        const value = match[2];

        var filledValue = '';
       /* if (key.match(/\d+e/)) {
          return renderEquation(value);
        } else if (key.match(imageRegex)) {
          const imageDetailsRegex = /!\[(.*?)\]\((.*?)\)/g;
          const imageDetails = value.replace("$1i$", "").replace("$1$", "").split(imageDetailsRegex);
          const altText = imageDetails[0];
          const imageUrl = imageDetails[1];
          return renderImage(altText, imageUrl);
        } else {
          return <span key={key}>{value}</span>;
        }*/

        fillingArray.push({
          "placeHolder": placeHolder,
          "key": key,
          "value": value,
          "filledValue": filledValue
        });
        content = content.replace(placeHolder, filledValue);
        if (position === regX.length) { return content; }
      }
    })
  }
//////exicute regex array end


  const renderEquation = (equation) => {
    return <MathJaxComponent content={equation} />;
  };

  const renderImage = (altText, imageUrl) => {
    return <img src={imageUrl} alt={altText} />;
  };

  return (
    <div>
      {splitContent.map((part, index) => {
        if (part.match(equationRegex)) {
          return renderEquation(part.replace("$1e$", "").replace("$1$", ""));
        } else if (part.match(imageRegex)) {
          const imageDetailsRegex = /!\[(.*?)\]\((.*?)\)/g;
          const imageDetails = part.replace("$1i$", "").replace("$1$", "").split(imageDetailsRegex);
          const altText = imageDetails[0];
          const imageUrl = imageDetails[1];
          return renderImage(altText, imageUrl);
        } else {
          return <span key={index}>{part}</span>;
        }
      })}
    </div>
  );
};

export default EquationTextWithImages;
