import React, { useEffect, useState } from 'react';
import './ResponsiveCards.css'; // Import the custom CSS file

const ResponsiveCards = () => {
    return (
      <div className="w3-row-padding w3-center w3-margin-top">
        {/* Add your Responsive Cards here */}
        <div className="w3-quarter ">
          <div className="w3-card w3-container" style={{ minHeight: '460px' }}>
  
            <div classname="container">
              <h1><b className="w3-text-orange">Course Catalog</b></h1>
              <p>Empowering Minds with Quality Education</p>
            </div>
  
            <i className="fa fa-graduation-cap w3-margin-bottom w3-text-orange" style={{ fontSize: '120px' }}></i>
  
            <div classname="container">
              <h2>Our Courses</h2>
              <div classname="course">
                <h3><b className="w3-text-orange">Course 1:</b> Advanced Mathematics</h3>
                <p>Description: Dive deep into advanced mathematical concepts and problem-solving techniques.</p>
              </div>
              <div classname="course">
                <h3><b className="w3-text-orange">Course 2: Modern Science</b></h3>
                <p>Description: Explore the latest discoveries and advancements in various fields of science.</p>
              </div>
              <div classname="course">
                <h3><b className="w3-text-orange">Course 3: Literature and Arts</b></h3>
                <p>Description: Immerse yourself in the world of literature and arts with our comprehensive course.</p>
              </div>
            </div>
  
          </div>
        </div>
  
        <div className="w3-quarter">
          <div className="w3-card w3-container" style={{ minHeight: '460px' }}>
  
            <div classname="container">
              <h1><b className="w3-text-green">Access Education Blog</b></h1>
              <p>Committed to Environmental Sustainability</p>
            </div>
  
            <i className="fa fa-leaf w3-margin-bottom w3-text-green" style={{ fontSize: '120px' }}></i>
  
            <div classname="container">
              <h2>Our Initiatives</h2>
              <div classname="initiative">
                <h3><b className="w3-text-green">Initiative 1:</b> Tree Plantation Drives</h3>
                <p>Description: Join us in planting trees to combat deforestation and promote greenery.</p>
              </div>
              <div classname="initiative">
                <h3><b className="w3-text-green">Initiative 2:</b> Wildlife Conservation</h3>
                <p>Description: Participate in our programs aimed at protecting endangered species.</p>
              </div>
              <div classname="initiative">
                <h3><b className="w3-text-green">Initiative 3:</b> Clean Water Projects</h3>
                <p>Description: Help us ensure clean and safe drinking water for communities in need.</p>
              </div>
            </div>
  
          </div>
        </div>
  
        <div className="w3-quarter">
          <div className="w3-card w3-container" style={{ minHeight: '460px' }}>
  
            <div classname="container">
              <h1><b className="w3-text-blue">Traverse Knowledge Tree</b></h1>
              <p>Compassionate Care for the Elderly</p>
            </div>
  
            <i className="fa fa-user w3-margin-bottom w3-text-blue" style={{ fontSize: '120px' }}></i>
  
            <div classname="container">
              <h2>Our Services</h2>
              <div classname="service">
                <h3><b className="w3-text-blue">Service 1:</b> Assisted Living</h3>
                <p>Description: Providing a safe and supportive environment for seniors with our assisted living facilities.</p>
              </div>
              <div classname="service">
                <h3><b className="w3-text-blue">Service 2:</b> Home Care</h3>
                <p>Description: Offering personalized care and support in the comfort of their own homes.</p>
              </div>
              <div classname="service">
                <h3><b className="w3-text-blue">Service 3:</b> Medical Support</h3>
                <p>Description: Ensuring access to medical care and health services for the elderly.</p>
              </div>
            </div>
  
          </div>
        </div>
  
        <div className="w3-quarter">
          <div className="w3-card w3-container" style={{ minHeight: '460px' }}>
  
            <div classname="container">
              <h1><b className="w3-text-red">Check Learning Progress</b></h1>
              <p>Promoting Healthy and Sustainable Food Choices</p>
            </div>
  
            <i className="fa fa-cutlery w3-margin-bottom w3-text-red" style={{ fontSize: '120px' }}></i>
  
            <div classname="container">
              <h2>Our Programs</h2>
              <div classname="program">
                <h3><b className="w3-text-red">Program 1:</b> Nutrition Workshops</h3>
                <p>Description: Educating communities about the importance of nutrition and healthy eating habits.</p>
              </div>
              <div classname="program">
                <h3><b className="w3-text-red">Program 2:</b> Sustainable Farming</h3>
                <p>Description: Promoting sustainable farming practices to ensure food security and environmental health.</p>
              </div>
              <div classname="program">
                <h3><b className="w3-text-red">Program 3:</b> Food Safety Campaigns</h3>
                <p>Description: Raising awareness about food safety standards and practices.</p>
              </div>
            </div>
  
          </div>
        </div>
        
      </div>
  
  
    );
  };

  export default ResponsiveCards;