import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import LessonSelector from './components/lessonSelector/LessonSelector';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';

import course1 from './course1.json';

const MenueIndex = ({ id }) => {
  var [course, setCourse] = useState(null);
  var [WaitingForResult, setWaitingForResult] = useState(false);
  var [searchInput, setSearchInput] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  id = (id) ? id : searchParams.get('id');

  const fetchLessons = async (id) => {
    try {

      //const response = course1;
      const url = `/courses/${id}`;
      const response = await axios.get(url);
      setCourse(response.data[0]);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };


  const updateLessons = async (id) => {
    try {

      //const response = course1;
      const url = `/courses/merge`;

      const sessionTokenId = getCookie('sessionTokenId');
      const refreshTokenId = getCookie('refreshTokenId');

      const payload = {
        sessionTokenId: sessionTokenId,
        refreshTokenId: refreshTokenId,
        courseId: id
      };
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.post(url, payload, { headers });

      if (response.data.sessionTokenId && response.data.refreshTokenId) {
        document.cookie = `sessionTokenId=${response.data.sessionTokenId}; expires=Thu, 31 Dec 2024 23:59:59 UTC; path=/`;
        document.cookie = `refreshTokenId=${response.data.refreshTokenId}; expires=Thu, 31 Dec 2024 23:59:59 UTC; path=/`;
      } else {
        deleteCookie('sessionTokenId');
        deleteCookie('refreshTokenId');
      }

      setCourse(response.data.result2);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const getCookie = (cookieName) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + '=')) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return null;
  }

  const deleteCookie = (cookieName) => {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  // Side navigation
  function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }

  useEffect(() => {
    var localyWaitingForResulttemp = false;

    if (!WaitingForResult && !localyWaitingForResulttemp && !course) {
      localyWaitingForResulttemp = true;
      setWaitingForResult(true);
      updateLessons(id);
    }
  }, [course]);

  return (
    <div>
      <TopMenu w3_open={w3_open} />
      <SideNavigation w3_close={w3_close} />
      <Header w3_open={w3_open} />
      {course ? (
        <div>
          <LessonSelector
            title="Lesson Selector"
            header="Lesson Header"
            marks="Lesson Marks"
            footer="Lesson Footer"
            course={course.data}
            courseId={course.id}
          />
        </div>
      ) : (
        <h1>Loading Lessons...</h1>
      )}
      <Footer />
    </div>
  );
};

export default MenueIndex;
