// language.js
import React from 'react';
import './Language.css'; // Import CSS for styling
import { useTranslation } from 'react-i18next';

const language = () => {
  const { t, i18n } = useTranslation('hub/home');
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <a onClick={() => changeLanguage('en')}>E </a>
      <a onClick={() => changeLanguage('si')}>සිං </a>
      <a onClick={() => changeLanguage('ta')}>த </a>
    </div>
  );
}

export default language;
