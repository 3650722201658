import React, { useState } from 'react';
import {
  Link
} from 'react-router-dom';
import './LegendsOfFunctions.css'; // Import CSS for styling
import ImageSlider from './../imageSlider/ImageSlider';

const LegendsOfFunctions = () => {

  const items_re = [
    { type: 'image', src: 're/re1.jpg' },
    { type: 'image', src: 're/re2.jpg' },
    { type: 'image', src: 're/re3.jpg' },
    { type: 'video', src: 'https://www.youtube.com/embed/tEMeBIXcs7s' },
    { type: 'image', src: 're/re4.jpg' },
    { type: 'image', src: 're/re5.jpg' },
  ];

  

  return (
    <div className="w3-light-gray">

      <div>
        <div className=" w3-row w3-whitee" id="legent1"></div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-orange w3-white" id="legent2">

          <div className="left-third-green w3-third w3-container w3-orange">
            <h2>Risara Education</h2>
            <p>Empowering minds with quality education. Discover our range of courses designed to enhance learning and skill development.</p>
            <br />
            <p><b>Innovative Learning Methods : </b> We utilize the latest educational technologies and innovative teaching methods to enhance the learning experience. From interactive online platforms to hands-on practical sessions, our approach ensures that students are engaged, motivated, and equipped with the skills they need to excel.
            </p>
            <p><b>Expert Instructors : </b> At Risara Education, learning is guided by highly qualified and experienced instructors who are dedicated to fostering a stimulating and supportive educational environment. Our faculty members are passionate about teaching and committed to student success.
            </p>
          </div>

          <div className="w3-third w3-container">

            <p><b className="w3-text-orange">Expert Instructors:</b> At Risara Education, learning is guided by highly qualified and experienced instructors who are dedicated to fostering a stimulating and supportive educational environment. Our faculty members are passionate about teaching and committed to student success.
            </p>
            <div className="w3-container w3-padding-small">
              <Link to="/search" className="w3-button w3-orange">Explore</Link>
            </div>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-orange">About</a>
            </div>
            <div className="w3-container w3-padding-small" id="rnc">
              <a className="w3-button w3-orange" >Contacts</a>
            </div>

          </div>

          <ImageSlider items={items_re}  id="re"/>

        </div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-green w3-white" id="legent3">

          <div className="w3-third w3-container w3-green">
            <h2>Risara Nature Care</h2>
            <p>Committed to environmental sustainability and conservation. Explore our initiatives to protect and nurture nature.</p>
            <br />
            <p><b>Sustainable Practices and Eco-friendly Solutions : </b>
              At Risara Nature Care, we implement and promote sustainable practices aimed at reducing our ecological footprint. From eco-friendly product alternatives to sustainable farming techniques, we are dedicated to creating a more sustainable world.</p>
            <p><b>Conservation Projects and Biodiversity Preservation : </b>
              Our conservation projects focus on protecting and restoring natural habitats, preserving biodiversity, and supporting endangered species. By collaborating with local and global partners, we strive to create lasting positive impacts on our natural world.</p>
          </div>
          <div className="w3-third w3-container">
            <p><b className="w3-text-green">Community Engagement and Education : </b>
              We believe in the power of community involvement and education. Through workshops, seminars, and community programs, we engage and educate individuals about the importance of environmental stewardship and how they can contribute to a healthier planet.</p>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-green">Explore</a>
            </div>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-green">About</a>
            </div>
            <div className="w3-container w3-padding-small" id="rec">
              <a className="w3-button w3-green" >Contacts</a>
            </div>
          </div>
          <ImageSlider items={items_re}  id="rnc"/>

        </div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-blue w3-white" id="legent4">
          <div className="w3-third w3-container w3-blue">
            <h2>Risara Elders Care</h2>
            <p>Providing compassionate care for the elderly. Learn about our comprehensive services aimed at ensuring their well-being.</p>
            <br />
            <p><b>Professional and Compassionate Staff : </b> Our team of trained professionals is committed to delivering compassionate care with respect and dignity. We prioritize building strong relationships with our residents, fostering a sense of community, and ensuring their happiness and satisfaction.</p>
            <p><b>Safe and Comfortable Environment : </b> We provide a safe, comfortable, and nurturing environment where seniors can thrive.<br /> Our state-of-the-art facilities are designed with their safety in mind, featuring accessible amenities and engaging activities that promote well-being and an active lifestyle.</p>
          </div>
          <div className="w3-third w3-container">
            <p><b className="w3-text-blue">Personalized Care Plans : </b> At Risara Elders Care, we understand that each individual has unique needs. Our dedicated team creates personalized care plans tailored to meet the physical, emotional, and social requirements of every senior, ensuring they receive the highest quality of care and support.</p>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-blue">Explore</a>
            </div>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-blue">About</a>
            </div>
            <div className="w3-container w3-padding-small" id="rfc" >
              <a className="w3-button w3-blue" >Contacts</a>
            </div>
          </div>
          <ImageSlider items={items_re}  id="rec"/>

        </div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-red w3-white" id="legent5">
          <div className="w3-third w3-container w3-red">
            <h2>Risara Food Care</h2>
            <p>Promoting healthy and sustainable food choices. Discover our programs focused on nutrition and food safety.</p>
            <br />
            <p><b>Healthy Eating Education : </b> Our programs are designed to educate individuals and communities on making nutritious food choices that promote overall health and well-being.</p>
            <p><b>Sustainable Agriculture Initiatives : </b> We support and promote sustainable farming practices that protect the environment while ensuring food security for future generations.</p>
            <p><b>Food Safety Standards : </b> We provide resources and training to ensure the highest standards of food safety, from farm to table, ensuring that the food you eat is safe and healthy.</p>
          </div>
          <div className="w3-third w3-container">
            <p><b className="w3-text-red">Food Safety Standards:</b> We provide resources and training to ensure the highest standards of food safety, from farm to table, ensuring that the food you eat is safe and healthy.</p>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-red">Explore</a>
            </div>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-red">About</a>
            </div>
            <div className="w3-container w3-padding-small">
              <a className="w3-button w3-red">Contacts</a>
            </div>
          </div>
          <ImageSlider items={items_re}  id="rfc"/>



        </div>

      </div>
      <br />

      <br />
    </div>
  );
};

export default LegendsOfFunctions;