import React, { useEffect, useState } from 'react';
import {
  Link,
  Route,
  HashRouter as Router,
  Switch,
} from 'react-router-dom';
import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';

//import NewsFeed from './../newsFeed/NewsFeed';
import TopMenu from './components/topMenu/TopMenu.js';
import SideNavigation from './components/sideNavigation/SideNavigation.js';
import LegendsOfFunctions from './components/legendsOfFunctions/LegendsOfFunctions.js';
import Welcome from './components/welcome/Welcome.js'
import ScrollToTopButton from './components/scrollToTopButton/ScrollToTopButton.js'
import '../../../i18n.js';

import './Blog.css'; // Import CSS for styling

import ButtonSet from './components/buttonSet/ButtonSet.js';

import { FaBackward, FaHome, FaImages, FaBlog, FaInfoCircle, FaEnvelope } from 'react-icons/fa'; // Import icons from react-icons

const buttons = [
  { icon: FaBackward, name: 'Marketplace', route: '/marketplace/' },
  { icon: FaHome, name: 'Home', route: '/' },
  { icon: FaImages, name: 'Gallery', route: '/marketplace/gallery' },
  { icon: FaBlog, name: 'Blog', route: '/marketplace/blog' },
  { icon: FaInfoCircle, name: 'About', route: '/marketplace/about' },
  { icon: FaEnvelope, name: 'Contact', route: '/marketplace/contact' }
];

const local = 'marketplace/blog';

// Main App component
function Blog() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.title = "Risara.lk";
  }, []);

  const handleButtonClick = (company) => {
    // Handle button click action
    console.log(`Clicked on ${company.name}`);
  };

  // Side navigation
  function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";

    setIsVisible(false);
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";

    if (document.body.scrollTop > 2 || document.documentElement.scrollTop > 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  return (
    <div>
      <TopMenu w3_open={w3_open} isVisible={isVisible} setIsVisible={setIsVisible} buttons={buttons} local={local} />
      <SideNavigation w3_close={w3_close} buttons={buttons} local={local} />
      <Header w3_open={w3_open} local={local} />
      <ButtonSet buttons={buttons} />
      <Welcome local={local} />

      <LegendsOfFunctions local={local} />

      <Footer local={local} />
      <ScrollToTopButton />
    </div>
  );
}

export default Blog;
