import React, { useState } from 'react';
import './LegendsOfFunctions.css'; // Import CSS for styling
import ImageSlider from '../imageSlider/ImageSlider';
import { useTranslation } from 'react-i18next';

const LegendsOfFunctions = ({local}) => {
  const { t } = useTranslation(local);

  const imagess = [];
  imagess["images1"] = [
    { type: 'image', src: 're/re1.jpg' },
    { type: 'image', src: 're/re2.jpg' },
    { type: 'image', src: 're/re3.jpg' },
    { type: 'video', src: 'https://www.youtube.com/embed/tEMeBIXcs7s' },
    { type: 'image', src: 're/re4.jpg' },
    { type: 'image', src: 're/re5.jpg' },
  ];

  imagess["images2"] = [
    { type: 'image', src: 'marketplace/marketplace1.jpg' },
    { type: 'image', src: 'marketplace/marketplace2.jpg' },
    { type: 'image', src: 'marketplace/marketplace3.jpg' },
    { type: 'image', src: 'marketplace/marketplace5.jpg' },
    { type: 'image', src: 'marketplace/marketplace6.jpg' },
  ];

  imagess["images3"] = [
    { type: 'image', src: 'aiandrobotics/aiandrobotics1.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics2.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics3.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics4.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics5.jpg' },
  ];

  imagess["images4"] = [
    { type: 'image', src: 'studio/studio1.jpg' },
    { type: 'image', src: 'studio/studio2.jpg' },
    { type: 'image', src: 'studio/studio3.jpg' },
    { type: 'image', src: 'studio/studio4.jpg' },
    { type: 'image', src: 'studio/studio5.jpg' },
  ];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; // Number of items to display per page

  // Calculate pagination values
  const legends = t('legendsOfFunctions', { returnObjects: true });
  const totalPages = Math.ceil(legends.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = legends.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Pagination = ({ currentPage, totalPages, onPageChange }) => (
    <div className="pagination-container">
      <button
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        className={`w3-button w3-light-gray`}
        disabled={currentPage === 1}
      >
        &laquo; Previous
      </button>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`w3-button ${currentPage === page ? 'w3-blue' : 'w3-light-gray'}`}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        className={`w3-button w3-light-gray`}
        disabled={currentPage === totalPages}
      >
        Next &raquo;
      </button>
    </div>
  );

  return (
    <div className="w3-light-gray">
      <div>
        <br />
        {/* Pagination Controls Top */}
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

        <div className="w3-row w3-white"></div>
        {currentItems.map((item, index) => (
          <div key={index}>
            <br />
            <div className={`size_limit w3-row w3-border w3-border-${item.color} w3-white`} id={`legent${index + 1}`}>
              <div className={`left-third-${item.color} w3-third w3-container w3-${item.color}`}>
                <h2>{item.mainHeader}</h2>
                <p>{item.mainParagraph}</p>
                <br />
                {item.subHeaders.map((subHeader, subIndex) => (
                  <div key={subIndex}>
                    <p><b>{subHeader.subHeader}</b> {subHeader.paragraph}</p>
                  </div>
                ))}
              </div>
              <div className="w3-third w3-container">
                <p><b className={`w3-text-${item.color}`}>{item.header}</b> {item.paragraph}</p>
                <div className="w3-container w3-padding-small">
                  <a href="https://risara.lk/education/" className={`w3-button w3-${item.color}`}>{item.explore}</a>
                </div>
                <div className="w3-container w3-padding-small">
                  <a href="https://risara.lk/education/" className={`w3-button w3-${item.color}`}>{item.about}</a>
                </div>
                <div className="w3-container w3-padding-small">
                  <a href="https://risara.lk/education/" className={`w3-button w3-${item.color}`}>{item.contacts}</a>
                </div>
              </div>
              <ImageSlider items={imagess[`${item.images}`]} id={`section-a${index + 2}`} color={`w3-${item.color}`} hoverColor={`w3-hover-${item.color}`} />
            </div>
          </div>
        ))}
        <br />

        {/* Pagination Controls Bottom */}
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

      </div>
      <br />
    </div>
  );
};

export default LegendsOfFunctions;
