import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import './Lesson.css';
import Accordion from './components/accordion/Accordion';
import LessonContent from './components/content/Content';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';

const LessonForm = ({ id, cid, lid }) => {
  const [formData, setFormData] = useState({ "marks": 10, "level": "1", "items": [] });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lessonId = (id) ? id : searchParams.get('id');
  const courseId = (cid) ? cid : searchParams.get('cid');
  const levelId = (lid) ? lid : searchParams.get('lid');


  useEffect(() => {
    loadLessonsList();
  }, []);

  const loadLessonsList = () => {
    const url = `/lessons/${lessonId}`; // Use the '/api' prefix to trigger the proxy

    return axios.get(url)
      .then(response => {
        console.log('Lessons list loaded successfully:', response.data);
        if (response.data && response.data[0]) {
          const resData = JSON.parse(response.data[0].data);
          setFormData(resData);
        }
        return response.data;
      })
      .catch(error => {
        console.error('Error loading Lessons List:', error);
        throw error;
      });
  };

  const renderFormItems = (items, level) => {
    if (!items) {
      return null;
    }
    return items.map((item, index) => (
     <>{ item.items ? (<Accordion
        title={item.heading?item.level +'-'+ item.heading:item.level}
        content={
          <div key={item.no} className='lesson-section'>
           
            {item.items && renderFormItems(item.items, item.level)}
            <br/>
          </div>
        }
      /> ):  item.content ?<LessonContent content={item.content} level={item.level} index={index} ></LessonContent>:<></>}</>
    ));
  };

   // Side navigation
   function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }
  
  return (
    <div className='lesson-form'>
      <TopMenu w3_open={w3_open} />
      <SideNavigation w3_close={w3_close} />
      <Header w3_open={w3_open} />
      <Accordion
        title={formData.heading}
        content={
          <div>
            {renderFormItems(formData.items, 1)}
           
          </div>
        }
      />
     <Link to={`/menue_index?id=${courseId}&lid=${levelId}`}>Menue Index</Link>
     <Footer />
    </div>
  );
};

export default LessonForm;
