import React, { useEffect, useState } from 'react';
import './SideNavigation.css'; // Import the custom CSS file
import { useTranslation } from 'react-i18next';
import ButtonSetLeft from '../buttonSetLeft/ButtonSet'
import { FaBook, FaShoppingCart, FaRobot, FaCamera } from 'react-icons/fa'; // Import icons
import { Link } from 'react-router-dom';

const SideNavigation = ({ w3_close, buttons,local }) => {
  const { t } = useTranslation(local);

  const handleButtonClick = (id) => {

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="w3-sidebar w3-bar-block w3-card w3-animate-left w3-center" style={{ display: 'none' }} id="mySidebar">
      <button className=" w3-border  w3-button w3-circle w3-image " onClick={w3_close}><i className="fa fa-remove" style={{
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '48px',
        margin: '0 auto',
        color: 'red', borderRadius: '50%'
      }}></i></button>
      <Link to='/' className="w3-bar-item w3-button"><h1 className="w3-xxxlarge"><b>{t("topMenu.risaraGroup")}</b></h1></Link>
      <Link to='/education' className="w3-bar-item w3-button w3-text-blue"><FaBook size='30'/> {t("sideNavigation.education")}</Link>
      <Link to='/marketplace' className="w3-bar-item w3-button w3-text-green"><FaShoppingCart size='30'/> {t("sideNavigation.marketplace")}</Link>
      <Link to='/AIandRobotics' className="w3-bar-item w3-button w3-text-orange"><FaRobot size='30'/> {t("sideNavigation.AIandRobotics")}</Link>
      <Link to='/studio' className="w3-bar-item w3-button w3-text-red"><FaCamera size='30'/> {t("sideNavigation.studio")}</Link>
      <ButtonSetLeft buttons={buttons} />
    </nav>
  );
};

export default SideNavigation;