import React, { useState } from 'react';

const LessonContent = ({ content, level, index }) => {

    // Define the form inputs for different types
    const renderFormInputs = () => {
        switch (content.type) {
            case 'type1':
                return (
                    <>
                       
                       <h1>{content.inputField1 || ''}</h1>
                       <p>{content.inputField2 || ''}</p>
                    </>
                );
            case 'type2':
                return (
                    <p>
                       {content.inputField3 || ''}
                    </p>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {renderFormInputs()}
        </div>
    );
};

export default LessonContent;
