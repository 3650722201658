// src/BlogPage.js
import React from 'react';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';

const BlogPage = () => {
  const blogPosts = [
    {
      title: "Welcome to Risara Education Blog",
      date: "May 24, 2024",
      content: "We are excited to share our insights and updates about education and learning. Stay tuned for more posts!",
    },
    {
      title: "Benefits of Online Learning",
      date: "May 20, 2024",
      content: "Online learning offers flexibility, accessibility, and a wide range of resources. Discover how it can benefit you.",
    },
    {
      title: "Top 10 Study Tips",
      date: "May 15, 2024",
      content: "Maximize your study sessions with these effective tips. From time management to active learning, we've got you covered.",
    },
  ];

  // Side navigation
  function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }

  return (
    <div className="w3-container">
      <TopMenu w3_open={w3_open} />
      <SideNavigation w3_close={w3_close} />
      <Header w3_open={w3_open} />

      <div className="w3-row">
        <div className="w3-col l8 s12">
          {blogPosts.map((post, index) => (
            <div key={index} className="w3-card-4 w3-margin w3-white">
              <div className="w3-container">
                <h3>
                  <b>{post.title}</b>
                </h3>
                <h5>{post.date}</h5>
              </div>
              <div className="w3-container">
                <p>{post.content}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="w3-col l4">
          <div className="w3-card w3-margin w3-margin-top">
            <div className="w3-container w3-white">
              <h4>About Us</h4>
              <p>
                Risara Education is dedicated to providing high-quality educational content and resources. Our blog covers a range of topics to help learners of all ages.
              </p>
            </div>
          </div>
          <hr />
          <div className="w3-card w3-margin">
            <div className="w3-container w3-padding w3-white">
              <h4>Popular Posts</h4>
              <ul className="w3-ul w3-hoverable w3-white">
                {blogPosts.map((post, index) => (
                  <li key={index} className="w3-padding-16">
                    <span className="w3-large">{post.title}</span>
                    <br />
                    <span>{post.date}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
};

export default BlogPage;
