import React, { useState } from 'react';
import './Feedback.css'; // Import the custom CSS file

const feedbacks = [
  { id: 1, user: 'K. Siriwardhana', comment: 'Great service and friendly staff!' },
  { id: 2, user: 'L. P. Perera', comment: 'I love the environment and the care provided.' },
  { id: 3, user: 'Kamalsiri Alvis', comment: 'Highly recommended for everyone!' },
  { id: 4, user: 'Jastin', comment: 'Fantastic experience overall.' },
  { id: 5, user: 'Peter D. mel', comment: 'Very professional and attentive.' },
  { id: 6, user: 'M. Kuruppu', comment: 'Amazing place and people.' },
  // Add more feedbacks as needed
];

const Feedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextFeedback = () => {
    setCurrentIndex((currentIndex + 2) % feedbacks.length);
  };

  const prevFeedback = () => {
    setCurrentIndex((currentIndex - 2 + feedbacks.length) % feedbacks.length);
  };

  const visibleFeedbacks = feedbacks.slice(currentIndex, currentIndex + 2).concat(
    feedbacks.slice(0, Math.max(0, currentIndex + 2 - feedbacks.length))
  );

  return (
    <div className="size_limit w3-container w3-center  w3-margin-top">
      <h2>User Feedback</h2>
      <div className="w3-row-padding w3-margin-top">
        {visibleFeedbacks.map(feedback => (
          <div className="w3-half" key={feedback.id}>
            <div className="w3-card w3-padding w3-margin w3-white">
              <h3>{feedback.user}</h3>
              <p><i>"{feedback.comment}"</i></p>
            </div>
          </div>
        ))}
      </div>
      <div className="navigation-buttons"  id="re">
        <button className="nav-button prev" onClick={prevFeedback}>
          ❮
        </button>
        <button className="nav-button next" onClick={nextFeedback}>
          ❯
        </button>
      </div>
      <br />
    </div>
  );
};

export default Feedback;
