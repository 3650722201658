import React from 'react';
import { Link } from 'react-router-dom';
import './ButtonSet.css'; // Import the CSS for styling and animation

const ButtonSet = ({ buttons }) => {
  return (
    <div className="">
      {buttons.map((button, index) => (
        <div key={index} className="w3-bar-item">
          <Link to={button.route} className="">
            <button.icon size={20} /> {/* Render icon with size 20 */}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ButtonSet;
