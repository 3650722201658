import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'si', 'ta'],
    fallbackLng: 'en',
    debug: true,
    ns: ['hub/home', 'aiandrobotics/home', 'studio/home', 'education/home', 'marketplace/home', 'hub/gallery', 'aiandrobotics/gallery', 'studio/gallery', 'education/gallery', 'marketplace/gallery','hub/blog', 'aiandrobotics/blog', 'studio/blog', 'education/blog', 'marketplace/blog','hub/about', 'aiandrobotics/about', 'studio/about', 'education/about', 'marketplace/about', 'hub/contact', 'aiandrobotics/contact', 'studio/contact', 'education/contact', 'marketplace/contact'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
