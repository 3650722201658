// Header.js
import React from 'react';
import './Header.css'; // Import CSS for styling
import SearchBox from './../searchBox/SearchBox';

const Header = ({ w3_open, handleSearchChange }) => {
  return (
    <header className="w3-container w3-theme w3-padding">
      <a className="w3-bar-item w3-button fa fa-bars w3-xlarge  w3-theme" onClick={w3_open}></a>
      <a href="#re" className="w3-bar-item w3-button w3-theme">Education</a>
      <a href="#rnc" className="w3-bar-item w3-button w3-theme">Nature</a>
      <a href="#rec" className="w3-bar-item w3-button w3-theme">Elders</a>
      <a href="#rfc" className="w3-bar-item w3-button w3-theme">Food</a>
      <div className="w3-center">
        <h1 className="w3-xxxlarge w3-animate-bottom">Risara Food Supplier Catalog</h1>
        <h4>Promoting healthy and sustainable food choices. Discover our programs focused on nutrition and food safety.</h4>
      </div>
      <SearchBox searchChange={handleSearchChange} />
    </header>
  );
}

export default Header;
