import React, { useState } from 'react';
import './TrustedCompanies.css'; // Import the custom CSS file
import { useTranslation } from 'react-i18next';

const sponsors = [
  { id: 1, name: 'Healthy Eats', imgSrc: 'https://example.com/healthy-eats-logo.png', icon: 'fa fa-apple fa-5x w3-text-green' },
  { id: 2, name: 'Fresh Farms', imgSrc: 'https://example.com/fresh-farms-logo.png', icon: 'fa fa-leaf fa-5x  w3-blue' },
  { id: 3, name: 'Tasty Treats', imgSrc: 'https://example.com/tasty-treats-logo.png', icon: 'fa fa-birthday-cake fa-5x w3-text-red' },
  { id: 4, name: 'Green Grocers', imgSrc: 'https://example.com/green-grocers-logo.png', icon: 'fa fa-shopping-cart fa-5x w3-green' },
  { id: 5, name: 'Organic Delights', imgSrc: 'https://example.com/organic-delights-logo.png', icon: 'fa fa-pagelines fa-5x w3-text-gray' }, // Using fa-pagelines as fa-seedling is not available in FA 4.3.0
  { id: 6, name: 'Gourmet Goodies', imgSrc: 'https://example.com/gourmet-goodies-logo.png', icon: 'fa fa-cutlery fa-5x w3-gray' }, // Using fa-cutlery as fa-utensils is not available in FA 4.3.0
  { id: 7, name: 'Veggie Ventures', imgSrc: 'https://example.com/veggie-ventures-logo.png', icon: 'fa fa-caret-square-o-right fa-5x w3-text-red' }, // fa-carrot is not available in FA 4.3.0, using a different icon is required
  { id: 8, name: 'Fruit Fusions', imgSrc: 'https://example.com/fruit-fusions-logo.png', icon: 'fa fa-lemon-o fa-5x w3-green' }, // Using fa-lemon-o as fa-lemon is not available in FA 4.3.0
  { id: 9, name: 'Nature\'s Bounty', imgSrc: 'https://example.com/natures-bounty-logo.png', icon: 'fa fa-tree fa-5x w3-text-blue' },
  { id: 10, name: 'Sustainable Sourcing', imgSrc: 'https://example.com/sustainable-sourcing-logo.png', icon: 'fa fa-recycle fa-5x w3-red' },
  { id: 11, name: 'Ethical Eateries', imgSrc: 'https://example.com/ethical-eateries-logo.png', icon: 'fa fa-heart fa-5x w3-text-orange' },
  { id: 12, name: 'Wholesome Wellness', imgSrc: 'https://example.com/wholesome-wellness-logo.png', icon: 'fa fa-medkit fa-5x  w3-gold' },
];

const TrustedCompanies = ({local}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation(local);

  const nextSponsor = () => {
    setCurrentIndex((currentIndex + 3) % sponsors.length);
  };

  const prevSponsor = () => {
    setCurrentIndex((currentIndex - 3 + sponsors.length) % sponsors.length);
  };

  const visibleSponsors = sponsors.slice(currentIndex, currentIndex + 3).concat(
    sponsors.slice(0, Math.max(0, currentIndex + 3 - sponsors.length))
  );

  return (
    <div className="w3-container w3-center">
      <h2>{t("trustedCompanies.title")}</h2>
      <div className="w3-display-container" style={{ maxWidth: '600px', margin: 'auto' }}>
        <div className="w3-row-padding w3-margin-top">
          {visibleSponsors.map(sponsor => (
            <div className="w3-third" key={sponsor.id}>
              {false && <img
                src={sponsor.imgSrc}
                alt={sponsor.name}
                className="w3-circle w3-image"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              />}
              <i className={`w3-button w3-border ${sponsor.icon} w3-xxxlarge w3-hover-opacity`}
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize:'48px',
                  margin: '0 auto',
                  color:'red',
                }}
              ></i>
              <p>{sponsor.name}</p>
            </div>
          ))}
        </div>
        <div className="navigation-buttons">
          <button className="nav-button prev" onClick={prevSponsor}>
            ❮
          </button>
          <button className="nav-button next" onClick={nextSponsor}>
            ❯
          </button>
        </div>
        <br />
      </div>
    </div>
  );
};

export default TrustedCompanies;
