import React from 'react';
import { useTranslation } from 'react-i18next';
import './Welcome.css'; // Import CSS for styling

const Welcome = ({local}) => {
  const { t } = useTranslation(local);

  return (
    <div className="welcome-container">
      <div className="size_limit w3-center">
        <h1>{t('welcome.title')}</h1>
        <h3>{t('welcome.subtitle')}</h3>
        <p>{t('welcome.paragraph1')}</p>
        <p>{t('welcome.paragraph2')}</p>
      </div>
    </div>
  );
};

export default Welcome;
