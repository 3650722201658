import React from 'react';
import { Link } from 'react-router-dom';
import './ButtonSet.css'; // Import the CSS for styling and animation

const ButtonSet = ({ buttons }) => {
  return (
    <div className="button-set">
      {buttons.map((button, index) => (
        <div key={index} className="animated-button">
          <Link to={button.route} className="icon-button">
            <button.icon size={25} /> {/* Render icon with size 32 */}
          </Link>
          <div className="button-name">{button.name}</div>
        </div>
      ))}
    </div>
  );
};

export default ButtonSet;
