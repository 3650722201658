// src/components/CategoryList.js
import React, { useState } from 'react';

const categories = ["Development", "Design", "Marketing", "Business"];

const CategoryList = ({ selectedCategories, setSelectedCategories, isExpanded, toggleExpand }) => {
  const handleCheckboxChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((cat) => cat !== category)
        : [...prev, category]
    );
  };

  return (
    <div className="w3-margin w3-padding w3-border">
      <button className="w3-button w3-blue w3-margin-bottom" onClick={toggleExpand}>
        {isExpanded ? "Collapse" : "Expand"} Categories
      </button>
      {isExpanded && (
        <div>
          {categories.map((category) => (
            <div key={category} className="w3-margin-bottom">
              <input 
                className="w3-check" 
                type="checkbox" 
                checked={selectedCategories.includes(category)} 
                onChange={() => handleCheckboxChange(category)} 
              />
              <label className="w3-validate">{category}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryList;
