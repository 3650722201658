import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { MathComponent } from "mathjax-react";

const MathJaxComponent = forwardRef(({ content }) => {
  return (
    (content) && <MathComponent tex={content} />
  );
});

export default MathJaxComponent;
