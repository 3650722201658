import React, { useEffect, useState } from 'react';
import './TopMenu.css'; // Import the custom CSS file
import { useTranslation } from 'react-i18next';
import Language from '../language/Language'
import { FaBook, FaShoppingCart, FaRobot, FaCamera } from 'react-icons/fa'; // Import icons
import ButtonSetTop from '../buttonSetTop/ButtonSet'

const TopMenu = ({ w3_open, isVisible, setIsVisible, buttons, local }) => {
    const { t, i18n } = useTranslation(local);

    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 2 || document.documentElement.scrollTop > 2) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = (id) => {

        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <div className={`top-menu w3-theme ${isVisible ? 'visible' : ''}`}>
            <a className="w3-bar-item w3-button fa fa-bars w3-xlarge w3-theme " onClick={w3_open}></a>
            <div className="ollowOnly400above w3-animate-bottom we-text-red"><a className="w3-bar-item w3-theme">{t("topMenu.risaraGroup")}</a></div>
            <a className="w3-bar-item education-link" onClick={() => handleButtonClick('section-a1')}><FaBook size='20'/> <span className='button-set-text'>{t("topMenu.education")}</span></a>
            <a className="w3-bar-item marketplace-link" onClick={() => handleButtonClick('section-a2')}><FaShoppingCart size='20'/><span className='button-set-text'>{t("topMenu.marketplace")}</span></a>
            <a className="w3-bar-item ai-robotics-link" onClick={() => handleButtonClick('section-a3')}><FaRobot size='20'/><span className='button-set-text'>{t("topMenu.AIandRobotics")}</span></a>
            <a className="w3-bar-item studio-link" onClick={() => handleButtonClick('section-a4')}><FaCamera size='20'/><span className='button-set-text'>{t("topMenu.studio")}</span></a>
            
            <ButtonSetTop buttons={buttons} />
            <Language></Language>
        </div>
    );
};

export default TopMenu;
