import React, { useState } from 'react';
import './LegendsOfFunctions.css'; // Import CSS for styling
import ImageSlider from './../imageSlider/ImageSlider';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const LegendsOfFunctions = ({local}) => {
  const { t } = useTranslation(local);

  const items_re = [
    { type: 'image', src: 're/re1.jpg' },
    { type: 'image', src: 're/re2.jpg' },
    { type: 'image', src: 're/re3.jpg' },
    { type: 'video', src: 'https://www.youtube.com/embed/tEMeBIXcs7s' },
    { type: 'image', src: 're/re4.jpg' },
    { type: 'image', src: 're/re5.jpg' },
  ];

  const items_marketplace = [
    { type: 'image', src: 'marketplace/marketplace1.jpg' },
    { type: 'image', src: 'marketplace/marketplace2.jpg' },
    { type: 'image', src: 'marketplace/marketplace3.jpg' },
    { type: 'image', src: 'marketplace/marketplace5.jpg' },
    { type: 'image', src: 'marketplace/marketplace6.jpg' },
  ];

  const items_aiandobotics = [
    { type: 'image', src: 'aiandrobotics/aiandrobotics1.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics2.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics3.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics4.jpg' },
    { type: 'image', src: 'aiandrobotics/aiandrobotics5.jpg' },
  ];

  const items_studio = [
    { type: 'image', src: 'studio/studio1.jpg' },
    { type: 'image', src: 'studio/studio2.jpg' },
    { type: 'image', src: 'studio/studio3.jpg' },
    { type: 'image', src: 'studio/studio4.jpg' },
    { type: 'image', src: 'studio/studio5.jpg' },
  ];

  return (
    <div className="w3-light-gray">

      <div>

        <div className=" w3-row w3-white" id="legent1"></div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-blue w3-white" id="legent2">
          <div className="left-third-blue w3-third w3-container w3-blue">
            <h2>{t('legendsOfFunctions.legent2.header')}</h2>
            <p>{t('legendsOfFunctions.legent2.paragraph1')}</p>
            <br />
            <p><b>{t('legendsOfFunctions.legent2.subHeader2')}</b> {t('legendsOfFunctions.legent2.paragraph2')}</p>
            <p><b>{t('legendsOfFunctions.legent2.subHeader3')}</b> {t('legendsOfFunctions.legent2.paragraph3')}</p>
          </div>

          <div className="w3-third w3-container">
            <p><b className="w3-text-blue">{t('legendsOfFunctions.legent2.subHeader3')}</b> {t('legendsOfFunctions.legent2.paragraph3')}</p>
            <div className="w3-container w3-padding-small">
              <Link to={'/education'} className="w3-button w3-blue">
                {t('legendsOfFunctions.legent2.explore')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/education'} className="w3-button w3-blue">
                {t('legendsOfFunctions.legent2.about')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/education'} className="w3-button w3-blue">
                {t('legendsOfFunctions.legent2.contacts')}
              </Link>
            </div>
          </div>

          <ImageSlider items={items_re} id="section-a2" color="w3-blue" hoverColor="w3-hover-blue" />

        </div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-green w3-white" id="legent3" >
          <div className="w3-third w3-container w3-green"> <h2>{t('legendsOfFunctions.legent3.header')}</h2>
            <p>{t('legendsOfFunctions.legent3.paragraph1')}</p>
            <br />
            <p><b>{t('legendsOfFunctions.legent3.subHeader2')}</b> {t('legendsOfFunctions.legent3.paragraph2')}</p>
            <p><b>{t('legendsOfFunctions.legent3.subHeader3')}</b> {t('legendsOfFunctions.legent3.paragraph3')}</p>
          </div>
          <div className="w3-third w3-container">
            <p><b className="w3-text-green">{t('legendsOfFunctions.legent3.subHeader3')}</b> {t('legendsOfFunctions.legent3.paragraph3')}</p>
            <div className="w3-container w3-padding-small">
              <Link to={'/marketplace'} className="w3-button w3-green">
                {t('legendsOfFunctions.legent3.explore')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/marketplace'} className="w3-button w3-green">
                {t('legendsOfFunctions.legent3.about')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/marketplace'} className="w3-button w3-green">
                {t('legendsOfFunctions.legent3.contacts')}
              </Link>
            </div>
          </div>

          <ImageSlider items={items_marketplace} id="section-a3" color="w3-green" hoverColor="w3-hover-green" />


        </div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-orange w3-white" id="legent4" >
          <div className="w3-third w3-container w3-orange">
            <h2>{t('legendsOfFunctions.legent4.header')}</h2>
            <p>{t('legendsOfFunctions.legent4.paragraph1')}</p>
            <br />
            <p><b>{t('legendsOfFunctions.legent4.subHeader2')}</b> {t('legendsOfFunctions.legent4.paragraph2')}</p>
            <p><b>{t('legendsOfFunctions.legent4.subHeader3')}</b> {t('legendsOfFunctions.legent4.paragraph3')}</p>
          </div>
          <div className="w3-third w3-container">
            <p><b className="w3-text-orange">{t('legendsOfFunctions.legent4.subHeader3')}</b> {t('legendsOfFunctions.legent4.paragraph3')}</p>
            <div className="w3-container w3-padding-small">
              <Link to={'/aiandrobotics'} className="w3-button w3-orange">
                {t('legendsOfFunctions.legent4.explore')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/aiandrobotics'} className="w3-button w3-orange">
                {t('legendsOfFunctions.legent4.about')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/aiandrobotics'} className="w3-button w3-orange">
                {t('legendsOfFunctions.legent4.contacts')}
              </Link>
            </div>
          </div>

          <ImageSlider items={items_aiandobotics} id="section-a4" color="w3-orange" hoverColor="w3-hover-orange" />


        </div>

        <br />

        <div className="size_limit w3-row w3-border w3-border-red w3-white" id="legent5" >
          <div className="w3-third w3-container w3-red">
            <h2>{t('legendsOfFunctions.legent5.header')}</h2>
            <p>{t('legendsOfFunctions.legent5.paragraph1')}</p>
            <br />
            <p><b>{t('legendsOfFunctions.legent5.subHeader2')}</b> {t('legendsOfFunctions.legent5.paragraph2')}</p>
            <p><b>{t('legendsOfFunctions.legent5.subHeader3')}</b> {t('legendsOfFunctions.legent5.paragraph3')}</p>
          </div>
          <div className="w3-third w3-container">
            <p><b className="w3-text-red">{t('legendsOfFunctions.legent5.subHeader3')}</b> {t('legendsOfFunctions.legent5.paragraph3')}</p>
            <div className="w3-container w3-padding-small">
              <Link to={'/studio'} className="w3-button w3-red">
                {t('legendsOfFunctions.legent5.explore')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/studio'} className="w3-button w3-red">
                {t('legendsOfFunctions.legent5.about')}
              </Link>
            </div>
            <div className="w3-container w3-padding-small">
              <Link to={'/studio'} className="w3-button w3-red">
                {t('legendsOfFunctions.legent5.contacts')}
              </Link>
            </div>
          </div>

          <ImageSlider items={items_studio} id="section-a5" color="w3-red" hoverColor="w3-hover-red" />


        </div>

      </div>
      <br />

      <br />
    </div>
  );
};

export default LegendsOfFunctions;