import React from 'react';

const FoodCard = ({ food, openSupplierModal }) => {
  return (
    <div className="w3-card-4 w3-margin w3-white">
      <div className="w3-container">
        <h3>{food.name}</h3>
        <p>{food.description}</p>
        <button 
          className="w3-button w3-teal w3-margin-bottom" 
          onClick={() => openSupplierModal(food)}
        >
          Show Suppliers
        </button>
      </div>
    </div>
  );
};

export default FoodCard;
