import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({ searchChange }) => {
  return (
    <div className="w3-container">
        <div className="w3-container w3-theme w3-padding-32">
        <form className="example" onSubmit={(e) => e.preventDefault()} style={{ margin: "auto", maxWidth: "60%" }}>
          <input type="text" placeholder="Food name.." name="search" className="w3-input w3-border"
            onChange={searchChange} />
          <button type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>
    </div>



  );
};

export default SearchBox;
