import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import './SearchPage.css';
import LoginPopUp from './components/loginPopUp/LoginPopUp';

import SearchBar from './components/searchBar/SearchBar';
import CategoryList from './components/categoryList/CategoryList';
import CourseList from './components/courseList/CourseList';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';

function SearchPage() {
  const [allCourses, setAllCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [user, setUser] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);

  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);

  const handleCredentialsSubmit = async (username, password, course) => {
    // Perform your credentials validation logic here
    const re = await useraccess_login_course_verify(username, password, course.id);
    if (re) {
      setShowPopup(false);
      setSelectedCourseId(null);
      // Navigate to the desired route
      history.push(course.launchUrl + '?id=' + course.id);
    } else {
      alert('Invalid credentials');
      setSelectedCourseId(null);
    }
  };


  const useraccess_login_course_refresh = async (courseId, sessionTokenId, refreshTokenId) => {
    try {
      const url = `/mcq/login/course/${courseId}/refresh`;
      const payload = {
        // Your payload data here
        sessionTokenId: sessionTokenId,
        refreshTokenId: refreshTokenId
      };
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await axios.post(url, payload, headers);

      if (response.data.sessionTokenId && response.data.refreshTokenId) {
        document.cookie = `sessionTokenId=${response.data.sessionTokenId}; expires=Thu, 31 Dec 2024 23:59:59 UTC; path=/`;
        document.cookie = `refreshTokenId=${response.data.refreshTokenId}; expires=Thu, 31 Dec 2024 23:59:59 UTC; path=/`;
        return true;
      } else {
        deleteCookie('sessionTokenId');
        deleteCookie('refreshTokenId');
      }
      return false;
    } catch (error) {
      console.error('Error fetching courses:', error);
      return false;
    }
  };

  const useraccess_login_course_verify = async (email, hashPW, courseId) => {
    try {
      const url = `/mcq/login/course/${courseId}/verify`;
      const payload = {
        email: email,
        hashPW: hashPW
      };
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await axios.post(url, payload, { headers });

      if (response.data.sessionTokenId && response.data.refreshTokenId) {
        document.cookie = `sessionTokenId=${response.data.sessionTokenId}; expires=Thu, 31 Dec 2024 23:59:59 UTC; path=/`;
        document.cookie = `refreshTokenId=${response.data.refreshTokenId}; expires=Thu, 31 Dec 2024 23:59:59 UTC; path=/`;
        return true;
      } else {
        deleteCookie('sessionTokenId');
        deleteCookie('refreshTokenId');
      }

      return false;

    } catch (error) {
      console.error('Error fetching courses:', error);
      return false;
    }
  };

  const getCookie = (cookieName) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + '=')) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return null;
  }

  const deleteCookie = (cookieName) => {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  const handleLinkClick = async (event, course) => {

    const sessionTokenId = getCookie('sessionTokenId');
    const refreshTokenId = getCookie('refreshTokenId');
    if (sessionTokenId && refreshTokenId) {
      const re = await useraccess_login_course_refresh(course.id, sessionTokenId, refreshTokenId);
      if (!re) {
        setSelectedCourseId(course.id);
        setShowPopup(true);
      } else {
        history.push(course.launchUrl + '?id=' + course.id);
      }
    } else {
      setSelectedCourseId(course.id);
      setShowPopup(true);
    }

  };

  const hideLoginPopUp = () => {
    setShowPopup(false);
  };

  const fetchCourses = async () => {
    try {
      const url = `/courses/users/1`;
      const response = await axios.get(url);

      setAllCourses(response.data);
      setCourses(response.data);

    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  // Side navigation
  function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (allCourses.length == 0) {
      fetchCourses();
    } else {
      // Filter courses based on search input
      const filteredCourses = allCourses.filter(
        (course) =>
          course.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          course.description.toLowerCase().includes(searchInput.toLowerCase())
      );

      setCourses(filteredCourses);
    }
  }, [searchInput]);

  return (
    <div>
      <TopMenu w3_open={w3_open} />
      <SideNavigation w3_close={w3_close} />
      <Header searchInput={searchInput} setSearchInput={setSearchInput} w3_open={w3_open} />
      <div className="w3-row">
        <div className="w3-col l3 m4 s12">
          <CategoryList
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            isExpanded={isExpanded}
            toggleExpand={toggleExpand}
          />
        </div>
        <div className={`w3-col ${isExpanded ? 'l9 m8 s12' : 'l12 m12 s12'}`}>
          <CourseList courses={courses} handleLinkClick={handleLinkClick}/>
        </div>
      </div>
      <Footer />



      {courses.map((course, index) => (
        <div>
          {course.launchUrl.startsWith('http') ? (
            <div></div>
          ) : (
            <div>
              {showPopup && selectedCourseId === course.id &&
                <LoginPopUp course={course} onSubmit={handleCredentialsSubmit} hideLoginPopUp={hideLoginPopUp} />
              }
            </div>
          )}
        </div>)
      )}

    </div>
  );
}

export default SearchPage;


