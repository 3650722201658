import React from 'react';

const CategoryList = ({ categories, selectCategory, selectedCategory }) => {
  return (
    <div className="w3-container">
      <div className="w3-card w3-margin">
        <div className="w3-container w3-light-grey w3-padding">
          <h4>Categories</h4>
        </div>
        <ul className="w3-ul w3-hoverable">
          {categories.map((category) => (
            <li
              key={category}
              className={`w3-padding-small ${category === selectedCategory ? 'w3-blue' : ''}`}
              onClick={() => selectCategory(category)}
              style={{ cursor: 'pointer' }}
            >
              {category}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CategoryList;
