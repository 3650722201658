import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LessonSelector.css';

const LessonSelector = ({ title, header, marks, footer, course, courseId }) => {
  const [language, setLanguage] = useState('EN');
  const [selectedLevel, setSelectedLevel] = useState(course.level);
  const [selectedItems, setSelectedItems] = useState(course.items);
  const [selectedLession, setSelectedLession] = useState(course.lession);
  const [isLIDHandle, setIsLIDHandle] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  var lid = searchParams.get('lid');

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleLessonClick = (level, lession) => {
    // Handle lesson click event here
    console.log('Lesson clicked:', level);
    setSelectedLevel(level);
    setSelectedLession(lession);
  };

  const handleItemClick = (level, items) => {
    // Handle lesson click event here
    console.log('Lesson clicked:', level);
    setSelectedLevel(level);
    setSelectedItems(items);
  };

  const handleBackClick = () => {
    // Handle back button click event here
    setSelectedLevel((prevLevel) => {
      const levels = prevLevel.split('.');
      levels.pop(); // Remove the last level
      const newLevel = levels.join('.');
      const parentItems = getParentItems(newLevel);
      setSelectedItems(parentItems);
      return newLevel;
    });
  };

  const getParentItems = (level) => {
    const levels = level.split('.');
    let items = course.items;
    for (let i = 1; i < levels.length; i++) {
      const currentLevel = levels.slice(0, i + 1).join('.');
      items = items.find((item) => item.level === currentLevel)?.items || [];
    }
    return items;
  };

  if (lid && isLIDHandle) {
    //when click back button from MCQ end.
    const levels = lid.split('.');
    levels.pop(); // Remove the last level
    const newLevel = levels.join('.');
    setSelectedLevel(newLevel);
    const parentItems = getParentItems(newLevel);
    setSelectedItems(parentItems);
    setIsLIDHandle(false);
  }

  const handleHomeClick = () => {
    // Handle home button click event here
    setSelectedLevel('1');
    setSelectedItems(course.items);
  };

  const circleColor = (value) => {
    var colorVal = "c0";
    if (value < 0) colorVal = "c0";
    else if (value >= 0 && value < 10) colorVal = "c1";
    else if (value >= 10 && value < 20) colorVal = "c2";
    else if (value >= 20 && value < 30) colorVal = "c3";
    else if (value >= 30 && value < 40) colorVal = "c4";
    else if (value >= 40 && value < 50) colorVal = "c5";
    else if (value >= 50 && value < 60) colorVal = "c6";
    else if (value >= 60 && value < 70) colorVal = "c7";
    else if (value >= 70 && value < 80) colorVal = "c8";
    else if (value >= 80 && value < 90) colorVal = "c9";
    else if (value >= 90 && value <= 100) colorVal = "c10";
    else if (value > 100) colorVal = "c11";
    return colorVal;
  }

  const lessonButtons = selectedItems.map((item, index) => (
    <li className='index-item' key={index}>
      <div className='item'>
        <span class="item-number">({item.no}).</span>{(item.lession && item.lessionType==='MCQ-LIST') ?
          <button className='item-button item-color1' onClick={() => handleLessonClick(item.level, item.lession)}>
            <Link to={'/quiz?id=' + item.lession + '&cid=' + courseId + '&lid=' + item.level}>
              <span className={`circle color-${circleColor(item.marks)}`} />
              {item.text[language] + "-" + item.level}
            </Link>
          </button> :
          (item.lession && item.lessionType==='LESSON') ?
          <button className='item-button item-color1' onClick={() => handleLessonClick(item.level, item.lession)}>
            <Link to={'/lesson?id=' + item.lession + '&cid=' + courseId + '&lid=' + item.level}>
              <span className={`circle color-${circleColor(item.marks)}`} />
              {item.text[language] + "-" + item.level}
            </Link>
          </button> :
          (item.items && item.items.length > 0 ? <button className='item-button item-color2' onClick={() => handleItemClick(item.level, item.items)}>
            <span className={`circle color-${circleColor(item.marks)}`} />
            {item.text[language] + "-" + item.level}
          </button> : 
          <span>Not finalized.</span>)}
      </div>
    </li>
  ));

  return (
    <div>
      <h1>{title}</h1>
      <div>
        <button onClick={handleBackClick}>Back</button>
        <button onClick={handleHomeClick}>Home</button>
        <br />
        Select Language:
        <label>
          <input
            type="radio"
            value="SI"
            checked={language === 'SI'}
            onChange={handleLanguageChange}
          />
          සිංහල
        </label>
        <label>
          <input
            type="radio"
            value="EN"
            checked={language === 'EN'}
            onChange={handleLanguageChange}
          />
          English
        </label>
        <label>
          <input
            type="radio"
            value="TA"
            checked={language === 'TA'}
            onChange={handleLanguageChange}
          />
          தமிழ்
        </label>
      </div>
      <div className='index-list-container'>
        <h2>{header}</h2>
        <p>{marks}</p>
        <ul className='index-list'>{lessonButtons}</ul>
      </div>
      <div>
        <h3>{footer}</h3>
      </div>
    </div>
  );
};

export default LessonSelector;
