import React, { useState } from 'react';
import './TrustedCompanies.css'; // Import the custom CSS file

const sponsors = [
  { id: 1, name: 'Sponsor 1', imgSrc: 're/re1.jpg' },
  { id: 2, name: 'Sponsor 2', imgSrc: 're/re2.jpg' },
  { id: 3, name: 'Sponsor 3', imgSrc: 're/re3.jpg' },
  { id: 4, name: 'Sponsor 4', imgSrc: 're/re4.jpg' },
  { id: 5, name: 'Sponsor 5', imgSrc: 're/re5.jpg' },
  { id: 6, name: 'Sponsor 6', imgSrc: 're/re1.jpg' },
  { id: 7, name: 'Sponsor 7', imgSrc: 're/re2.jpg' },
  { id: 8, name: 'Sponsor 8', imgSrc: 're/re3.jpg' },
  { id: 9, name: 'Sponsor 9', imgSrc: 're/re4.jpg' },
  { id: 10, name: 'Sponsor 10', imgSrc: 're/re5.jpg' },
  { id: 11, name: 'Sponsor 11', imgSrc: 're/re1.jpg' },
  { id: 12, name: 'Sponsor 12', imgSrc: 're/re2.jpg' },
  // Add more sponsors as needed
];

const TrustedCompanies = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSponsor = () => {
    setCurrentIndex((currentIndex + 3) % sponsors.length);
  };

  const prevSponsor = () => {
    setCurrentIndex((currentIndex - 3 + sponsors.length) % sponsors.length);
  };

  const visibleSponsors = sponsors.slice(currentIndex, currentIndex + 3).concat(
    sponsors.slice(0, Math.max(0, currentIndex + 3 - sponsors.length))
  );

  return (
    <div className="w3-container w3-center">
      <h2>Our Sponsors</h2>
      <div className="w3-display-container" style={{ maxWidth: '600px', margin: 'auto' }}>
        <div className="w3-row-padding w3-margin-top">
          {visibleSponsors.map(sponsor => (
            <div className="w3-third" key={sponsor.id}>
              <img
                src={sponsor.imgSrc}
                alt={sponsor.name}
                className="w3-circle w3-image"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              />
              <p>{sponsor.name}</p>
            </div>
          ))}
        </div>
        <div className="navigation-buttons">
          <button className="nav-button prev" onClick={prevSponsor}>
            ❮
          </button>
          <button className="nav-button next" onClick={nextSponsor}>
            ❯
          </button>
        </div>
        <br/>
      </div>
    </div>
  );
};

export default TrustedCompanies;
