import React from 'react';

const KnowledgeTreeRelations = ({ selected, previous, next, setSelectedKnowledgeTreeId }) => {
  const renderTableRows = () => {
    const maxLength = Math.max(selected.length, previous.length, next.length);

    const rows = [];
    for (let i = 0; i < maxLength; i++) {
      rows.push(
        <tr key={i}>
          <td>
            {previous[i] && (
              <button onClick={() => setSelectedKnowledgeTreeId(previous[i].id)}>
                {previous[i].id }<br/>{previous[i].name && previous[i].name}
              </button>
            )}
          </td>
          <td>
            {selected[i] && (
              <button onClick={() => setSelectedKnowledgeTreeId(selected[i].id)}>
                {selected[i].id}<br/>{selected[i].name && selected[i].name}
              </button>
            )}
          </td>
          <td>
            {next[i] && (
              <button onClick={() => setSelectedKnowledgeTreeId(next[i].id)}>
                {next[i].id}<br/>{next[i].name && next[i].name}
              </button>
            )}
          </td>
        </tr>
      );
    }
    return rows;
  };

  return (
    <div>
      <h2>KnowledgeTree Relationships</h2>
      <table>
        <thead>
          <tr>
            <th>Previous KnowledgeTree ID</th>
            <th>Selected KnowledgeTree ID</th>
            <th>Next KnowledgeTree ID</th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>
    </div>
  );
};

export default KnowledgeTreeRelations;
