import React, { useEffect, useState } from 'react';
import './TopMenu.css'; // Import the custom CSS file
import { useTranslation } from 'react-i18next';
import Language from '../language/Language'
import ButtonSetTop from '../buttonSetTop/ButtonSet'
import { FaBook, FaShoppingCart, FaRobot, FaCamera } from 'react-icons/fa'; // Import icons
import { Link } from 'react-router-dom';
import SearchBar from '../searchBar/SearchBar'

const TopMenu = ({ w3_open, isVisible, setIsVisible, buttons, local }) => {
    const { t } = useTranslation(local);

    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 2 || document.documentElement.scrollTop > 2) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleButtonClick = (id) => {

        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <div className={`top-menu w3-theme ${isVisible ? 'visible' : ''}`}>
            <a className="w3-bar-item w3-button fa fa-bars w3-xlarge w3-theme " onClick={w3_open}></a>
            <div className="ollowOnly400above w3-animate-bottom we-text-red"><Link to='/' className="w3-bar-item w3-theme">{t("topMenu.risaraGroup")}</Link></div>
            <Link className="w3-bar-item education-link"  to='/education'><FaBook size='20'/> <span className='button-set-text'>{t("topMenu.education")}</span></Link>
            <Link className="w3-bar-item marketplace-link"  to='/marketplace'><FaShoppingCart size='20'/><span className='button-set-text'>{t("topMenu.marketplace")}</span></Link>
            <Link className="w3-bar-item ai-robotics-link"  to='/aiandrobotics'><FaRobot size='20'/><span className='button-set-text'> {t("topMenu.AIandRobotics")}</span></Link>
            <Link className="w3-bar-item studio-link"  to='/studio'><FaCamera size='20'/><span className='button-set-text'> {t("topMenu.studio")}</span></Link>
            <ButtonSetTop buttons={buttons} />
            <Language></Language>
            <SearchBar/>
        </div>
    );
};

export default TopMenu;
