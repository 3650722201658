// Footer.js
import React from 'react';
import './Footer.css'; // Import CSS for styling

const Footer = () => {
  return (

    <footer className="w3-container w3-theme-dark w3-padding-16">

      <div className="w3-row">
        <div className="w3-third w3-container">
          <h3>About Risara Group</h3>
          <p>Risara Group is committed to empowering lives and enriching communities through our diverse range of services and initiatives.</p>
        </div>
        <div className="w3-third w3-container">
          <h3>Contact Us</h3>
          <p>Email: info@risaragroup.com</p>
          <p>Phone: +1 123-456-7890</p>
        </div>
        <div className="w3-third w3-container">
          <h3>Follow Us</h3>
          <ul className="social-links">
            <li><a href="#" className="w3-button w3-text-white">Facebook</a></li>
            <li><a href="#" className="w3-button w3-text-white">Twitter</a></li>
            <li><a href="#" className="w3-button w3-text-white">Instagram</a></li>
            <li><a href="#" className="w3-button w3-text-white">LinkedIn</a></li>
          </ul>
        </div>
      </div>

      <div className="w3-row">
        <p>&copy; 2024 Risara Group</p>
      </div>
      <div style={{ position: 'relative', bottom: '55px' }} className="w3-tooltip w3-right">
        <span className="w3-text w3-theme-light w3-padding">Go To Top</span>
        <a className="w3-text-white" href="#myHeader"><span className="w3-xlarge">
          <i className="fa fa-chevron-circle-up"></i></span></a>
      </div>
    </footer>

  );

}

export default Footer;
