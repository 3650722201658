import React from 'react';

const SocialMediaIcons = () => {
  return (
    <div className="w3-xlarge w3-section">
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-facebook-official w3-hover-opacity" style={{ marginRight: '10px' }}></i>
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-instagram w3-hover-opacity" style={{ marginRight: '10px' }}></i>
      </a>
      <a href="https://www.snapchat.com" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-snapchat w3-hover-opacity" style={{ marginRight: '10px' }}></i>
      </a>
      <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-pinterest-p w3-hover-opacity" style={{ marginRight: '10px' }}></i>
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-twitter w3-hover-opacity" style={{ marginRight: '10px' }}></i>
      </a>
      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-linkedin w3-hover-opacity" style={{ marginRight: '10px' }}></i>
      </a>
    </div>
  );
};

export default SocialMediaIcons;
