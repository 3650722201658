// ButtonContainer.js
import React from 'react';
import './ButtonContainer.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faBlog, faTree, faChartLine } from '@fortawesome/free-solid-svg-icons';

const ButtonContainer = ({ handleButtonClick }) => {
  const companies = [
    { name: 'Course Catalog', icon: faBookOpen, color: 'w3-orange', description: 'Explore a wide range of courses designed to empower your future.', href: '#re' },
    { name: 'Access Education Blog', icon: faBlog, color: 'w3-green', description: 'Stay updated with our latest articles and insights on education.', href: '#rnc' },
    { name: 'Traverse Knowledge Tree', icon: faTree, color: 'w3-blue', description: 'Join our community and share your knowledge with others.', href: '#rec' },
    { name: 'Check Learning Progress', icon: faChartLine, color: 'w3-red', description: 'Monitor your progress and stay motivated throughout your learning journey.', href: '#rfc' }
  ];

  const handleButtonClick1 = (company) => {
    
    const element = document.getElementById(company.href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    handleButtonClick(company);
  };

  return (
    <div className="size_limit w3-container w3-center  w3-margin-top">
      <div className="button-container">
        {companies.map((company, index) => (
          <button key={index} className={`company-button ${company.color}`} onClick={() => handleButtonClick1(company)}>
            <FontAwesomeIcon icon={company.icon} size="3x" />
            <div className="company-info">
              <h1>{company.name}</h1>
              <p>{company.description}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}

export default ButtonContainer;
