import React, { useState, useEffect } from 'react';

const SupplierModal = ({ selectedFood, closeModal }) => {
  const [activeTab, setActiveTab] = useState('retail');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="w3-modal" style={{ display: 'block' }}>
      <div className="w3-modal-content w3-card-4">
        <header className="w3-container w3-teal">
          <span
            onClick={closeModal}
            className="w3-button w3-display-topright"
          >&times;</span>
          <h2>Suppliers</h2>
        </header>

        <div className="w3-container w3-center">
          <h5>{selectedFood.name}</h5>
          <p>{selectedFood.description}</p>
          <div className="w3-bar w3-light-grey">
            <button className={`w3-bar-item w3-button ${activeTab === 'retail' ? 'w3-blue' : ''}`} onClick={() => handleTabChange('retail')}>Retail</button>
            <button className={`w3-bar-item w3-button ${activeTab === 'wholesale' ? 'w3-blue' : ''}`} onClick={() => handleTabChange('wholesale')}>Wholesale</button>
          </div>
          <div>
            {activeTab === 'retail' && selectedFood.retailSuppliers && (
              <table className="w3-table w3-striped w3-bordered w3-margin-top">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th>Price</th>
                    <th>Quality</th>
                    <th>Quantity</th>
                    <th>Address</th>
                    <th>Telephone</th>
                    <th>Order</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFood.retailSuppliers.map((supplier) => (
                    <tr key={supplier.id}>
                      <td>{supplier.name}</td>
                      <td>{supplier.price}</td>
                      <td>{supplier.quality}</td>
                      <td>{supplier.quantity}</td>
                      <td>{supplier.address}</td>
                      <td>{supplier.telephone}</td>
                      <td>{supplier.orderMechanism}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {activeTab === 'wholesale' && selectedFood.wholesaleSuppliers && (
              <table className="w3-table w3-striped w3-bordered w3-margin-top">
                <thead>
                  <tr>
                    <th>Supplier</th>
                    <th>Price</th>
                    <th>Quality</th>
                    <th>Quantity</th>
                    <th>Address</th>
                    <th>Telephone</th>
                    <th>Order</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFood.wholesaleSuppliers.map((supplier) => (
                    <tr key={supplier.id}>
                      <td>{supplier.name}</td>
                      <td>{supplier.price}</td>
                      <td>{supplier.quality}</td>
                      <td>{supplier.quantity}</td>
                      <td>{supplier.address}</td>
                      <td>{supplier.telephone}</td>
                      <td>{supplier.orderMechanism}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <footer className="w3-container w3-teal">
          <button
            className="w3-button w3-margin"
            onClick={closeModal}
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  );
};

export default SupplierModal;



