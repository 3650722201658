// src/components/CourseList.js
import React from 'react';
import CourseCard from '../courseCard/CourseCard';
import './CourseList.css';

const CourseList = ({ courses, handleLinkClick }) => {
  return (
    <div className="w3-row-padding w3-stretch">
      <section  className="w3-center" id="courseList">
      {courses.map((course) => (
        <CourseCard index={course.id} course={course} handleLinkClick={handleLinkClick}/>
      ))}
      </section>
    </div>
  );
};

export default CourseList;
