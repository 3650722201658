import React from 'react';
import './SearchBar.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = () => {
    return (
        <div className="w3-container w3-theme w3-padding-32">
            <form className="example" action="action_page.php" style={{ margin: "auto", maxWidth: "60%" }}>
                <input type="text" placeholder="Search courses or subjects.." name="search" />
                <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </form>
        </div>
    );
};
 
export default SearchBar;
