// src/LearningProgress.js
import React from 'react';
import PropTypes from 'prop-types';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';

const LearningProgress = () => {
    const user = {
        name: "John Doe",
    };

    const progress = [
        { title: "React Basics", progress: 80 },
        { title: "Advanced JavaScript", progress: 65 },
        { title: "CSS for Beginners", progress: 50 },
        { title: "Full Stack Development", progress: 90 },
    ];

    // Side navigation
    function w3_open() {
        var x = document.getElementById("mySidebar");
        x.style.width = "100%";
        x.style.fontSize = "40px";
        x.style.paddingTop = "10%";
        x.style.display = "block";
    }
    function w3_close() {
        document.getElementById("mySidebar").style.display = "none";
    }

    return (
        <div> <TopMenu w3_open={w3_open} />
            <SideNavigation w3_close={w3_close} />
            <Header w3_open={w3_open} />
            <div className="w3-container">
                <header className="w3-center w3-padding-32 w3-white">
                    <h1 className="w3-xxlarge">Welcome, {user.name}</h1>
                    <p>Here is your learning progress</p>
                </header>

                <div className="w3-row">
                    {progress.map((course, index) => (
                        <div key={index} className="w3-card-4 w3-margin w3-white">
                            <div className="w3-container w3-padding">
                                <h3>{course.title}</h3>
                                <div className="w3-light-grey w3-round">
                                    <div
                                        className="w3-container w3-blue w3-round"
                                        style={{ width: `${course.progress}%` }}
                                    >
                                        {course.progress}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LearningProgress;
