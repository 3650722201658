// Header.js
import React from 'react';
import './Header.css'; // Import CSS for styling

const Header = ({w3_open}) => {
  return (
    <header className="w3-container w3-theme w3-padding" id="myHeader">
      <i className="fa fa-bars w3-xlarge w3-button w3-theme" onClick={w3_open}></i>
      <div className="w3-center">
        <h4>Empowering Future Generations</h4>
        <h1 className="w3-xxxlarge w3-animate-bottom">Risara Education</h1>
      </div>
    </header>
  );
}

export default Header;
