import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EquationTextWithImages from './components/equationTextWithImages/EquationTextWithImages';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TopMenu from './components/topMenu/TopMenu';
import SideNavigation from './components/sideNavigation/SideNavigation';

import { useLocation } from 'react-router-dom';
import axios from 'axios';

/*const quizData = {
  "mcqList": [
    {
      "no": "1",
      "question": "Question 1 -The quadratic equation $e$(x-h)^2+k=0$$ can be represented as: $e$ax^2 + bx + c = 0$$. $i$![Example Image](example-image.jpg)$$",
      "answers": [
        {
          "answer": "1",
          "text":"answer 1.1 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": true,
          "note":"note 1.1 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)"
        },
        {
          "answer": "2",
          "text":"answer 1.2 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 1.2 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)"
        },
        {
          "answer": "3",
          "text":"answer 1.3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 1.3"
        },
        {
          "answer": "4",
          "text":"answer 1.4 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 1.4"
        }
      ]
    },
    {
      "no": "2",
      "question": "Question 2- The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
      "answers": [
        {
          "answer": "1",
          "text":"answer 2.1 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 2.1"
        },
        {
          "answer": "2",
          "text":"answer 2.2 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": true,
          "note":"note 2.2"
        },
        {
          "answer": "3",
          "text":"answer 2.3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 2.3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)"
        },
        {
          "answer": "4",
          "text":"answer 2.4 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 2.4"
        }
      ]
    },
    {
      "no": "3",
      "question": "Question 3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
      "answers": [
        {
          "answer": "1",
          "text":"answer 3.1 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 3.1"
        },
        {
          "answer": "2",
          "text":"answer 3.2 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 3.2"
        },
        {
          "answer": "3",
          "text":"answer 3.3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 3.3"
        },
        {
          "answer": "4",
          "text":"answer 3.4 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": true,
          "note":"note 3.4"
        }
      ]
    },
    {
      "no": "4",
      "question": "Question 4",
      "answers": [
        {
          "answer": "1",
          "text":"answer 4.1 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": true,
          "note":"note 4.1"
        },
        {
          "answer": "2",
          "text":"answer 4.2 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 4.2"
        },
        {
          "answer": "3",
          "text":"answer 4.3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 4.3"
        },
        {
          "answer": "4",
          "text":"answer 4.4 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 4.4"
        }
      ]
    },
    {
      "no": "5",
      "question": "Question 5",
      "answers": [
        {
          "answer": "1",
          "text":"answer 5.1 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": true,
          "note":"note 5.1"
        },
        {
          "answer": "2",
          "text":"answer 5.2 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": true,
          "note":"note 1"
        },
        {
          "answer": "3",
          "text":"answer 5.3 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 1"
        },
        {
          "answer": "4",
          "text":"answer 5.4 - The quadratic equation can be represented as: $ax^2 + bx + c = 0$. ![Example Image](example-image.jpg)",
          "correct": false,
          "note":"note 1"
        }
      ]
    }
  ],
  "time": "1"
};
*/


const Quiz = ({ id, cid, lid }) => {
  const [quizData, setQuizData] = useState({});

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(1);
  const [quizStarted, setQuizStarted] = useState(false);
  const [waitingForResult, setWaitingForResult] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mcqId = (id) ? id : searchParams.get('id');
  const courseId = (cid) ? cid : searchParams.get('cid');
  const levelId = (lid) ? lid : searchParams.get('lid');

  useEffect(() => {
    fetchQuizData();
  }, []);

  const fetchQuizData = () => {
    const url = `/mcq/${mcqId}`; // Use the '/api' prefix to trigger the proxy

    return axios.get(url)
      .then(response => {
        console.log('MCQ list loaded successfully:', response.data);
        const resData = JSON.parse(response.data[0].data);
        setQuizData(resData);

        const durationInSeconds = parseInt(resData.time) * 60;
        setTimeRemaining(durationInSeconds);

        const timer = setInterval(() => {
          setTimeRemaining(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
      })
      .catch(error => {
        console.error('Error loading MCQ list:', error);
        throw error;
      });
  };

  useEffect(() => {
    if (timeRemaining === 0) {
      setShowResults(true);
    }
  }, [timeRemaining]);

  const handleAnswerSelect = (questionNo, answer) => {
    const currentSelectedAnswers = selectedAnswers[questionNo] || [];

    // Check if the answer is already selected
    const answerIndex = currentSelectedAnswers.indexOf(answer);

    if (answerIndex > -1) {
      // If the answer is already selected, remove it from the selected answers
      const updatedAnswers = [
        ...currentSelectedAnswers.slice(0, answerIndex),
        ...currentSelectedAnswers.slice(answerIndex + 1)
      ];

      setSelectedAnswers(prevSelectedAnswers => ({
        ...prevSelectedAnswers,
        [questionNo]: updatedAnswers
      }));
    } else {
      // If the answer is not selected, add it to the selected answers
      setSelectedAnswers(prevSelectedAnswers => ({
        ...prevSelectedAnswers,
        [questionNo]: [...currentSelectedAnswers, answer]
      }));
    }
  };


  const handleStartQuiz = () => {
    setQuizStarted(true);
  };

  const handleNextQuestion = () => {
    const question = quizData['mcqList'][currentQuestion];
    const selectedAnswer = selectedAnswers[question.no];
    const correctAnswers = question.answers
      .filter(answer => answer.correct)
      .map(answer => answer.answer)
      .sort();

    if (selectedAnswer && correctAnswers.includes(selectedAnswer[0])) {
      setScore(score + 1);
    }

    setCurrentQuestion(currentQuestion + 1);

    if (currentQuestion === quizData['mcqList'].length - 1) {
      setShowResults(true);
    }
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  // Helper function to update the value and maintain the pattern
  function updateValueAndMaintainPattern(jsonObj, keyToUpdate, newValue) {
    const keysToUpdate = keyToUpdate.split(".");

    jsonObj[keyToUpdate] = newValue;

    if (keysToUpdate.length > 1) {
      var sum = 0; var avg = 0;
      const parentKey = keysToUpdate.slice(0, keysToUpdate.length - 1).join(".");
      for (let i = 1; jsonObj[parentKey + "." + i] != null; i++) {
        sum = sum + jsonObj[parentKey + "." + i];
        avg = sum / i;
      }

      updateValueAndMaintainPattern(jsonObj, parentKey, avg);
    }
  }

  // Example JSON
  const data = {
    "1": 7,
    "1.1": 5,
    "1.1.1": 8,
    "1.1.1.1": 10,
    "1.1.1.2": 8,
    "1.1.1.3": 10,
    "1.1.1.4": 7,
    "1.1.1.5": 5,
    "1.1.2": 3,
    "1.1.3": 9,
    "1.1.4": 5,
    "1.1.5": 10,
    "1.2": 10,
    "1.3": 9,
    "1.4": 1,
    "1.5": 10,
  };



  const submitResults = async () => {
    // Update a value and maintain the pattern
    updateValueAndMaintainPattern(data, "1.1.1.1", 7); // Update value at "1.1.1.1" to 7
    try {
      const url = `/mcq/result/verify`;

      const sessionTokenId = getCookie('sessionTokenId');
      const refreshTokenId = getCookie('refreshTokenId');

      const payload = {
        sessionTokenId: sessionTokenId,
        refreshTokenId: refreshTokenId,
        level: levelId,
        courseId: courseId,
        mcqId: mcqId,
        selectedAnswers: selectedAnswers
      };
      const headers = {
        'Content-Type': 'application/json',
      };

      /*
      const submissionPromise = axios.post(url, payload, { headers });
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => reject(new Error('Timeout occurred')), 500000); // Set timeout to 5 seconds
      });

      const response = await Promise.race([submissionPromise, timeoutPromise]);
*/

      const response = await axios.post(url, payload, { headers });

      if (response.data.sessionTokenId && response.data.refreshTokenId) {
        document.cookie = `sessionTokenId=${response.data.sessionTokenId}; expires=Thu, 31 Dec 2023 23:59:59 UTC; path=/`;
        document.cookie = `refreshTokenId=${response.data.refreshTokenId}; expires=Thu, 31 Dec 2023 23:59:59 UTC; path=/`;
      } else {
        deleteCookie('sessionTokenId');
        deleteCookie('refreshTokenId');
      }

      return response.data.gradeUpdated;

    } catch (error) {
      console.error('Error fetching courses:', error);
      return false;
    }
  }

  const renderResults = () => {
    return quizData['mcqList'].map((question, index) => {
      const selectedAnswersForQuestion = selectedAnswers[question.no] || [];
      const correctAnswers = question.answers
        .filter(answer => answer.correct)
        .map(answer => answer.answer);

      const isCorrect = correctAnswers.length === selectedAnswersForQuestion.length &&
        correctAnswers.every(answer => selectedAnswersForQuestion.includes(answer));

      const incorrectAnswers = question.answers
        .filter(answer => !answer.correct && selectedAnswersForQuestion.includes(answer.answer));

      return (
        <div key={index}>
          <h4>Question {question.no}</h4>
          <p><EquationTextWithImages content={question.question} /></p>
          <p>Your answer(s): {selectedAnswersForQuestion.join(', ')}</p>
          {isCorrect ? (
            <p><span role="img" aria-label="correct">✅</span> Correct</p>
          ) : (
            <>
              <p>
                <span role="img" aria-label="incorrect">❌</span> Incorrect. Correct answer(s): {correctAnswers.join(', ')}
              </p>
              {incorrectAnswers.map((incorrectAnswer, index) => (
                <p key={index}>Note for incorrect answer: <EquationTextWithImages content={incorrectAnswer.note} /></p>
              ))}

            </>
          )}
          <p><EquationTextWithImages content={question.discription} /></p>
        </div>
      );
    });
  };

  const getCookie = (cookieName) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + '=')) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return null;
  }

  const deleteCookie = (cookieName) => {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  const handleSetWaitingForResult = (b) => {
    setWaitingForResult(b);
  };

  const getWaitingForResult = () => {
    return waitingForResult;
  };

  const handleSetSubmissionSuccess = (b) => {
    setSubmissionSuccess(b);
  };

  const getSubmissionSuccess = () => {
    return submissionSuccess;
  };




  function AsyncResults({ getSubmissionSuccess, handleSetSubmissionSuccess, getWaitingForResult, handleSetWaitingForResult }) {
    var [submissionSuccess, setSubmissionSuccess] = useState(getSubmissionSuccess());
    var [localyWaitingForResult, setLocalyWaitingForResult] = useState(false);
    var localyWaitingForResulttemp = false;

    useEffect(() => {

      // Create a flag to track the component's mounted state
      let isMounted = true;

      async function fetchResults() {

        try {
          /* const submissionPromise = submitResults();
           const timeoutPromise = new Promise((resolve, reject) => {
             setTimeout(() => reject(new Error('Timeout occurred')), 500000); // Set timeout to 5 seconds
           });

           const success = await Promise.race([submissionPromise, timeoutPromise]);
          */

          handleSetWaitingForResult(true);
          const success = await submitResults();

          handleSetSubmissionSuccess(success);

        } catch (error) {
          console.error('Error submitting results:', error);

          handleSetSubmissionSuccess(false);
          handleSetWaitingForResult(true);

        }


      }
      if (!getWaitingForResult() && !localyWaitingForResult && isMounted && !localyWaitingForResulttemp) {
        localyWaitingForResulttemp = true;
        setLocalyWaitingForResult(true);
        handleSetWaitingForResult(true);
        fetchResults();
      }

      return () => {
        // Set the mounted flag to false when the component is unmounted
        isMounted = false;
      };

    }, [submissionSuccess]);

    if (getSubmissionSuccess() === null) {
      // Render a loading state if the submission is in progress
      return <p>Loading results...</p>;
    } else if (getSubmissionSuccess()) {
      // Render the results if the submission was successful
      return (
        <div>
          {renderResults()}
          <p>Your score: {score}/{quizData['mcqList'].length}</p>
        </div>
      );
    } else {
      // Render a failure message if the submission failed
      return <p>Your score has not been submitted.</p>;
    }
  }


  // Side navigation
  function w3_open() {
    var x = document.getElementById("mySidebar");
    x.style.width = "100%";
    x.style.fontSize = "40px";
    x.style.paddingTop = "10%";
    x.style.display = "block";
  }
  function w3_close() {
    document.getElementById("mySidebar").style.display = "none";
  }

  const question = quizData['mcqList'] ? quizData['mcqList'][currentQuestion] : null;
  const isFirstQuestion = currentQuestion === 0;
  const isLastQuestion = quizData['mcqList'] ? currentQuestion === quizData['mcqList'].length - 1 : false;

  return (
    <div>
      <TopMenu w3_open={w3_open} />
      <SideNavigation w3_close={w3_close} />
      <Header w3_open={w3_open} />
      {(showResults) ? (

        <div>
          <h2>Quiz Results</h2>

          {getCookie('sessionTokenId') && getCookie('refreshTokenId') ? (
            <AsyncResults getSubmissionSuccess={getSubmissionSuccess} handleSetSubmissionSuccess={handleSetSubmissionSuccess} getWaitingForResult={getWaitingForResult} handleSetWaitingForResult={handleSetWaitingForResult} />
          ) : (
            <p>Please log in to view the results.</p>
          )}
          <Link to={`/menue_index?id=${courseId}&lid=${levelId}`}>Menue Index</Link>
        </div>

      ) : (!quizStarted) ? (
        <div>
          <h2>Quiz</h2>
          <button onClick={handleStartQuiz}>Start Quiz</button>
        </div>
      ) : (currentQuestion >= quizData['mcqList'].length) ? (
        <div>
          <h2>Quiz Complete</h2>
          <p>Your score: {score}/{quizData['mcqList'].length}</p>
          {renderResults()}
        </div>
      ) : (
        <div>
          <h2>Question {question.no}</h2>
          <p>{question.question}</p>
          <p><EquationTextWithImages content={question.question} /></p>
          <ul>
            {question.answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="checkbox"
                    name={`answer-${question.no}`}
                    value={answer.answer}
                    checked={(selectedAnswers[question.no] || []).includes(answer.answer)}
                    onChange={() => handleAnswerSelect(question.no, answer.answer)}
                  />
                  {answer.answer}
                  <EquationTextWithImages content={answer.text} />
                </label>
              </li>
            ))}
          </ul>
          <button onClick={handlePreviousQuestion} disabled={isFirstQuestion}>
            Previous
          </button>
          <button onClick={handleNextQuestion} disabled={selectedAnswers[question.no] === undefined}>
            {isLastQuestion ? 'Submit' : 'Next'}
          </button>
          <p>Time Remaining: {Math.floor(timeRemaining / 60)}:{timeRemaining % 60}</p>
        </div>
      )}
      <br />
      <Footer />
    </div>
  );

};

export default Quiz;