import React from 'react';
import './SearchBar.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchBar = ({local}) => {
    const { t } = useTranslation(local);

    return (
        <div className="w3-container w3-theme w3-padding-32">
            <div className="formSearch" style={{ margin: "auto", maxWidth: "60%" }}>
                <input type="text" placeholder="Search courses or subjects.." name="search" />
                <Link className="w3-center buttonSearch"  to='/marketplace/foodCatalog'>
                    <FontAwesomeIcon icon={faSearch} />
                </Link>
            </div>
        </div>
    );
};
 
export default SearchBar;
