import React, { useEffect, useState } from 'react';
import './ResponsiveCards.css'; // Import the custom CSS file
import { useTranslation } from 'react-i18next';

const ResponsiveCards = ({local}) => {
  const { t } = useTranslation(local);

  return (
    <div className=" size_limit w3-row-padding w3-center w3-margin-top">
      {/* Add your Responsive Cards here */}
      <div className="w3-half">
        <div className="w3-card w3-container" style={{ minHeight: '460px' }}>
          <div className="container">
            <h1><b className="w3-text-blue">{t('responsiveCards.responsiveCard1.title')}</b></h1>
            <p>{t('responsiveCards.responsiveCard1.subtitle')}</p>
          </div>
          <i className="fa fa-graduation-cap w3-margin-bottom w3-text-blue" style={{ fontSize: '120px' }}></i>
          <div className="container">
            <h2>{t('responsiveCards.responsiveCard1.ourCourses')}</h2>
            <div className="course">
              <h3><b className="w3-text-blue">{t('responsiveCards.responsiveCard1.course1TitleNo')}</b>{t('responsiveCards.responsiveCard1.course1Title')}</h3>
              <p>{t('responsiveCards.responsiveCard1.course1Description')}</p>
            </div>
            <div className="course">
              <h3><b className="w3-text-blue">{t('responsiveCards.responsiveCard1.course2TitleNo')}</b>{t('responsiveCards.responsiveCard1.course2Title')}</h3>
              <p>{t('responsiveCards.responsiveCard1.course2Description')}</p>
            </div>
            <div className="course">
              <h3><b className="w3-text-blue">{t('responsiveCards.responsiveCard1.course3TitleNo')}</b>{t('responsiveCards.responsiveCard1.course3Title')}</h3>
              <p>{t('responsiveCards.responsiveCard1.course3Description')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w3-half">
        <div className="w3-card w3-container" style={{ minHeight: '460px' }}>
          <div className="container">
            <h1><b className="w3-text-green">{t('responsiveCards.responsiveCard2.title')}</b></h1>
            <p>{t('responsiveCards.responsiveCard2.subtitle')}</p>
          </div>
          <i className="fa fa-graduation-cap w3-margin-bottom w3-text-green" style={{ fontSize: '120px' }}></i>
          <div className="container">
            <h2>{t('responsiveCards.responsiveCard2.ourCourses')}</h2>
            <div className="course">
              <h3><b className="w3-text-green">{t('responsiveCards.responsiveCard2.course1TitleNo')}</b>{t('responsiveCards.responsiveCard2.course1Title')}</h3>
              <p>{t('responsiveCards.responsiveCard2.course1Description')}</p>
            </div>
            <div className="course">
              <h3><b className="w3-text-green">{t('responsiveCards.responsiveCard2.course2TitleNo')}</b>{t('responsiveCards.responsiveCard2.course2Title')}</h3>
              <p>{t('responsiveCards.responsiveCard2.course2Description')}</p>
            </div>
            <div className="course">
              <h3><b className="w3-text-green">{t('responsiveCards.responsiveCard2.course3TitleNo')}</b>{t('responsiveCards.responsiveCard2.course3Title')}</h3>
              <p>{t('responsiveCards.responsiveCard1.course3Description')}</p>
            </div>
          </div>
        </div>
      </div>

    </div>


  );
};

export default ResponsiveCards;