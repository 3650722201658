// Footer.js
import React from 'react';
import './Footer.css'; // Import CSS for styling
import SocialMediaIcons from '../socialMediaIcons/SocialMediaIcons';
import { useTranslation } from 'react-i18next';

const Footer = ({local}) => {
  const { t } = useTranslation(local);

  return (

    <footer className="w3-container w3-theme-dark w3-padding-16">
      <div className="w3-row">
        <div className="w3-third w3-container">
          <h3>{t('footer.aboutUs')}</h3>
          <p>{t('footer.aboutUsText')}</p>
        </div>
        <div className="w3-third w3-container">
          <h3>{t('footer.contactUs')}</h3>
          <p>{t('footer.email')}</p>
          <p>{t('footer.phone')}</p>
        </div>
        <div className="w3-third w3-container">
          <h3>{t('footer.followUs')}</h3>
          <SocialMediaIcons />
        </div>
      </div>
      <div className="w3-row">
        <p>{t('footer.footerText')}</p>
      </div>
    </footer>

  );

}

export default Footer;
