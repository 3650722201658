import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import './KnowledgeTree.css';
import KnowledgeTreeRelations from '../knowledgeTreeRelations/KnowledgeTreeRelations';

const KnowledgeTree = ({id}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedKnowledgeTreeId, setSelectedKnowledgeTreeId] = useState((id) ? id : searchParams.get('id'));
  const [knowledgeTreeRelations, setKnowledgeTreeRelations] = useState({
    selected: [],
    previous: [],
    next: [],
  },[selectedKnowledgeTreeId]);

  useEffect(() => {
    loadLessonsList();
  }, [selectedKnowledgeTreeId]);

  const loadLessonsList = () => {
    const url = `/knowledgeTree/${selectedKnowledgeTreeId}/tree`; // Use the '/api' prefix to trigger the proxy

    return axios.get(url)
      .then(response => {
        console.log('selectedKnowledgeTree list loaded successfully:', response.data);
        if (response.data) {
          const resData = response.data;
          setKnowledgeTreeRelations(resData);
        }
        return response.data;
      })
      .catch(error => {
        console.error('Error loading Lessons List:', error);
        throw error;
      });
  };

  return (
    <div>
      <h1>KnowledgeTree Relationships</h1>
      <KnowledgeTreeRelations
        selected={knowledgeTreeRelations.selected}
        previous={knowledgeTreeRelations.previous}
        next={knowledgeTreeRelations.next}
        setSelectedKnowledgeTreeId={setSelectedKnowledgeTreeId}
      />
    </div>
  );
};

export default KnowledgeTree;
