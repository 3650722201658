// src/components/SearchBar.js
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

const SearchBar = ({ searchInput, setSearchInput }) => {
  return (


      <div className="w3-container w3-theme w3-padding-32">
        <form className="example" onSubmit={(e) => e.preventDefault()} style={{ margin: "auto", maxWidth: "60%" }}>
          <input type="text" placeholder="Search courses or subjects.." name="search" className="w3-input w3-border"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)} />
          <button type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>

        

      </div>
  );
};

export default SearchBar;
